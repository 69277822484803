// @ts-nocheck
import { lazy } from "react";

const lsKey = "page-has-been-force-refreshed";

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.sessionStorage.getItem(lsKey) || "false"
    );
    try {
      const component = await componentImport();
      window.sessionStorage.setItem(lsKey, "false");
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.sessionStorage.setItem(lsKey, "true");
        window.location.reload();
        return;
      }
      throw error;
    }
  });

export default lazyWithRetry;
