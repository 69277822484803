import { CircularProgress, Stack } from "@mui/material";

const Fallback = () => (
  <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    sx={{ height: "calc(100vh - 140px)" }}
  >
    <CircularProgress />
  </Stack>
);

export default Fallback;
