/* istanbul ignore file */
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { ReactNode, Suspense } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import App from "./App";
import initInstrumentation from "./helpers/instrumentation";
import "./i18n/i18n";
import Fallback from "./routes/Fallback";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";

dayjs.extend(isToday);
dayjs.extend(relativeTime);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.defaults.font.family = "Karla";

const Auth0Wrapper = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ""}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={(appState) => {
        const url = appState?.returnTo
          ? appState.returnTo
          : window.location.pathname;
        navigate(url);
      }}
    >
      {children}
    </Auth0Provider>
  );
};

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  <Suspense fallback={<Fallback />}>
    <Router>
      <Auth0Wrapper>
        <App />
      </Auth0Wrapper>
    </Router>
  </Suspense>
);

initInstrumentation();
