// @ts-nocheck
import { useEffect, useState } from "react";
import useClientNavigate from "src/hooks/useClientNavigate";

function useStripe(metricsData) {
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useClientNavigate();
  const [stripeStatus, setStripeStatus] = useState(searchParams.get("status"));
  const isCanceled = metricsData?.status === "canceled";
  const isExpired = metricsData?.status === "expired";
  const isInactive = metricsData?.status === "inactive";

  useEffect(() => {
    if (isCanceled || isExpired || isInactive) {
      navigate("/account/inactive");
    }
  }, [history, metricsData]);

  /**
   * when stripe upgrade process succeeds, there will be a ?status=success parameter in the url
   * give the backend some time to reflect the changes to the plan type
   */
  useEffect(() => {
    if (stripeStatus === "success") {
      setTimeout(() => {
        setStripeStatus("loaded");
        window.history.replaceState(null, "", window.location.pathname);
      }, 5000);
    }
  }, []);

  return stripeStatus === "success";
}

export default useStripe;
