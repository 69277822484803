import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Footer from "src/components/Layout/Footer";
import useDocumentTitle from "src/hooks/useDocumentTitle";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";

const MaintenancePage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("maintenance.pageTitle"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{
          minHeight: "calc(100vh - 56px)",
        }}
      >
        <Typography variant="h1" fontWeight="bold" align="center">
          {t("maintenance.title")}
        </Typography>
        <ErrorOutlineOutlinedIcon sx={{ fontSize: 72 }} color="error" />
        <Typography variant="h6" align="center" sx={{ maxWidth: "360px" }}>
          {t("maintenance.subtitle")}
        </Typography>
        <HandymanOutlinedIcon
          sx={{ fontSize: 220, margin: "9px 0", color: "rgb(97, 97, 97)" }}
        />
      </Stack>
      <Footer />
    </Box>
  );
};

export default MaintenancePage;
