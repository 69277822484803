const EmailCheckIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="120"
    width="120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#CCCCCC"
      d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h8v-2H4V8l8 5 8-5v5h2V6c0-1.1-.9-2-2-2zm-8 7L4 6h16l-8 5z"
    />
    <path
      fill="#0cbc34"
      d="M17.34 22l-3.54-3.54 1.41-1.41 2.12 2.12 4.24-4.24L23 16.34 17.34 22z"
    />
  </svg>
);

export default EmailCheckIcon;
