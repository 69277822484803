import { User } from "@auth0/auth0-react";
import { datadogLogs } from "@datadog/browser-logs";
import { Metrics } from "src/types/Metrics";
import { Profile } from "src/types/Profile";
import posthog, { PostHogConfig } from "posthog-js";
import "posthog-js/dist/recorder.js";

export const flags = {
  isProd: process.env.REACT_APP_ENV === "prod",
  isDeployed: ["dev", "prod"].includes(process.env.REACT_APP_ENV ?? ""),
};

function initLogging() {
  if (!flags.isDeployed) return;
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN ?? "",
    service: process.env.REACT_APP_DD_SERVICE,
    env: process.env.REACT_APP_ENV,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ["error", "warn"],
    forwardReports: ["csp_violation", "deprecation", "intervention"],
    sessionSampleRate: 100,
    version: process.env.REACT_APP_BUILD_SHA,
    beforeSend: (log) => {
      const statusCode = log?.http?.status_code ?? 0;
      const ignoredCodes = [401, 403];
      if (ignoredCodes.includes(statusCode)) {
        return false;
      }
      const chromeExtError = log?.error?.stack?.includes("chrome-extension://");
      if (chromeExtError) {
        return false;
      }
      // prevent rules validation posts from filling up datadog logs
      const url = log?.http?.url;
      const pathIncludesRuleValidation = url?.includes("/v1/rules/validate");
      if (pathIncludesRuleValidation && statusCode === 400) {
        return false;
      }
      return true;
    },
  });
}

function initLoggingProps(user: User, profile: Profile, metrics: Metrics) {
  if (!flags.isDeployed) return;
  const name = `${profile?.givenName} ${profile?.familyName}`;
  const { email } = user;
  datadogLogs.setUser({
    id: user.sub,
    name,
    email,
    plan: metrics?.planName,
  });
}

function initAnalytics(user: User) {
  if (!flags.isProd) return;
  const { email } = user;
  const domains = ["@443id.com", "@verosint.com"];
  const isInternal =
    domains.some((d) => email?.includes(d)) && email?.includes("testing");
  if (isInternal) return;
  const key = "phc_sUDF7xtvWX4xkBlyLVBgikzNScnGMP8gnVqrnqNDcr3";
  const options: Partial<PostHogConfig> = {
    api_host: "https://app.posthog.com",
    session_recording: {
      maskTextSelector: ".mask",
    },
    on_request_error(_error) {},
  };

  posthog.init(key, options);
  posthog.identify(user.sub, { email, name });
}

function initInstrumentation() {
  initLogging();
}

export default initInstrumentation;
export { initLoggingProps, initAnalytics };
