import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { t } from "i18next";

dayjs.extend(customParseFormat);

// cube doesnt always include the current day in the response, so today will be tomorrow here
const today = dayjs().toISOString();

const filterOptions = [
  {
    label: t("usageDashboard.lastDays", { count: 1 }),
    dateQuery: [dayjs().subtract(1, "day").toISOString(), today],
    dateOptions: { month: "numeric", day: "numeric" },
    testId: "last1day",
  },
  {
    label: t("usageDashboard.lastDays", { count: 7 }),
    dateQuery: [dayjs().subtract(7, "day").toISOString(), today],
    dateOptions: { month: "numeric", day: "numeric" },
    testId: "last7days",
  },
  {
    label: t("usageDashboard.lastDays", { count: 30 }),
    dateQuery: [dayjs().subtract(30, "day").toISOString(), today],
    dateOptions: { month: "numeric", day: "numeric" },
    testId: "last30days",
  },
  {
    label: t("usageDashboard.lastMonths", { count: 3 }),
    dateQuery: [dayjs().subtract(3, "month").toISOString(), today],
    dateOptions: { month: "numeric", day: "numeric" },
    testId: "last3months",
  },
];

export default filterOptions;
