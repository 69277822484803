import { Box } from "@mui/material";
import MaterialTable from "@material-table/core";
import { tableIcons } from "src/helpers/tableIcons";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, MouseEvent } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { Event } from "src/types/EventResponse";
import {
  usePostEventGroupSearchMutation,
  usePostGraphSearchMutation,
} from "src/services/443id";
import { useSnackbar } from "notistack";
import "src/components/EventExplorer/Table/ExplorerTable.scss";
import { ExpTabValue } from "src/types/Common";
import useExplorerTableOptions from "../EventExplorer/Table/useExplorerTableOptions";
import { GraphSearchRequest } from "src/types/Graph";
import { getID } from "../Dashboards/utils";
import { EventGroupByRequest, OrderEnum } from "src/types/EventGroupByRequest";
import { OperatorEnum } from "src/types/Filter";
import { EventGroupByResponse } from "src/types/EventGroupByResponse";
import useClientNavigate from "src/hooks/useClientNavigate";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch } from "react-redux";
import { setAccounts } from "src/redux/Accounts/slice";
import AccountsSelector from "src/redux/Accounts/selector";
import { useSelector } from "src/redux/index";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

export interface Row {
  email: string;
  accountId: string;
  timestamp: string;
  id: string;
  lastSeen: string;
}

const AccountsTable = () => {
  const { t } = useTranslation();
  const tableRef = useRef<any>();
  const tableOptions = useExplorerTableOptions(ExpTabValue.Events);
  const [fetchEventData, { isLoading }] = usePostEventGroupSearchMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [fetchSearchData] = usePostGraphSearchMutation();
  const { accounts, search } = useSelector(AccountsSelector);
  const dispatch = useDispatch();
  const navigate = useClientNavigate();

  const onRowClick = (_event?: MouseEvent, rowData?: Event) => {
    const payload: GraphSearchRequest = {
      type: "ACCT",
      value: rowData?.accountId,
    };
    fetchSearchData(payload)
      .unwrap()
      .then((f) => {
        const result = f?.[0];
        if (!result) throw new Error("no results");
        const id = getID("ACCT", result);
        const path = `/accounts/account-details/${id}`;
        navigate(path, {
          lastSeen: rowData?.lastSeen,
          accountId: rowData?.accountId,
        });
      })
      .catch(() => {
        enqueueSnackbar(t("accountDetails.accountErrorMsg"), {
          variant: "error",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  };

  const runFetchEventData = () => {
    const q: EventGroupByRequest = {
      filters: [
        {
          operator: OperatorEnum.Or,
          filters: [
            {
              field: "identifiers.accountId",
              operator: OperatorEnum.StartsWith,
              values: [search ?? ""],
            },
            {
              field: "identifiers.email",
              operator: OperatorEnum.StartsWith,
              values: [search ?? ""],
            },
          ],
        },
      ],
      groupBy: "identifiers.accountId",
      limit: 100,
      events: {
        limit: 1,
        fields: ["identifiers.email", "identifiers.timestamp"],
        orders: [
          {
            field: "identifiers.timestamp",
            order: OrderEnum.Desc,
          },
        ],
      },
      timeRange: [dayjs().utc().subtract(180, "day").toISOString()],
    };

    fetchEventData(q)
      .unwrap()
      .then((d: EventGroupByResponse) => {
        if (!d?.groups) {
          dispatch(setAccounts([]));
          return;
        }
        const data = d?.groups.flatMap((group) => {
          return group.events.map((event, i) => {
            return {
              email: event.identifiers.email,
              accountId: group.value,
              timestamp: dayjs(event.identifiers.timestamp).format(
                "MM/DD/YYYY h:mm:ss A"
              ),
              id: group.value + i,
              lastSeen: event.identifiers.timestamp,
            };
          });
        });
        dispatch(setAccounts(data));
      })
      .catch((e: { data: { error: string } }) => {
        enqueueSnackbar(
          t("accountDetails.accountsErrorMsg") + ": " + e?.data?.error,
          {
            variant: "error",
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }
        );
      });
  };

  const debouncedFetchAccounts = useDebouncedCallback(runFetchEventData, 500);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current && accounts.length !== 0) {
      isInitialMount.current = false;
    } else {
      debouncedFetchAccounts();
    }
  }, [search]);

  return (
    <Box
      sx={{ p: 2 }}
      className="explorer-table explorer-events-table"
      data-testid="events-table"
    >
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          Container: (props) => <Box {...props} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          Toolbar: () => <Box />,
        }}
        columns={[
          {
            title: t("global.accountId"),
            field: "accountId",
          },
          {
            title: t("global.email"),
            field: "email",
          },
          {
            title: t("accountDetails.lastSeen"),
            field: "lastSeen",
            defaultSort: "desc",
            render(data) {
              return data.timestamp;
            },
          },
        ]}
        onRowClick={onRowClick}
        data={accounts ?? []}
        isLoading={isLoading}
        // @ts-ignore
        options={tableOptions}
      />
    </Box>
  );
};

export default AccountsTable;
