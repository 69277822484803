import { Typography, Link } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import useClientNavigate from "src/hooks/useClientNavigate";

const OtherSteps = () => {
  const { t } = useTranslation();
  const navigate = useClientNavigate();

  return (
    <>
      <Typography
        variant="body1"
        component="p"
        sx={{ mb: "4px", mt: "4px" }}
        data-testid="other-description"
      >
        {t("welcomeChecklist.otherDescription")}
      </Typography>
      <ol
        style={{
          margin: 0,
          padding: "18px",
        }}
        data-testid="other-list"
      >
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.otherStep1">
              text
              <Link
                onClick={() => navigate("/account/api-keys", {})}
                sx={{
                  textDecoration: "none",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                data-testid="other-api-link"
              ></Link>
            </Trans>
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.otherStep2">
              text
              <Link
                href="https://docs.verosint.com/reference/events"
                rel="noopener noreferrer"
                target="_blank"
                sx={{ textDecoration: "none", fontWeight: "600" }}
                data-testid="other-events-link"
              ></Link>
            </Trans>
          </Typography>
        </li>
      </ol>
    </>
  );
};

export default OtherSteps;
