// @ts-nocheck
import dayjs from "dayjs";
import { GraphSearchResponse, GraphSearchRequest } from "src/types/Graph";
import { EventRequest } from "src/types/EventRequest";

export function getGranularityOld(timeFrame) {
  return getGranularity(timeFrame.dateQuery[0], timeFrame.dateQuery[1]);
}

export function getGranularity(date1, date2) {
  if (!date1 || !date2) return "day";
  const diff = dayjs(date2).diff(dayjs(date1), "day", true);
  if (diff > 180) {
    return "month";
  }
  if (diff > 88) {
    return "week";
  }
  if (diff > 1) {
    return "day";
  }
  if (diff > 0.24) {
    return "hour";
  }
  return "minute";
}

export function getID(type: string, d: GraphSearchResponse) {
  if (type === "PRINT") {
    return d.printID;
  }
  if (type === "ACCT") {
    return d.accountID;
  }
  return d.identifierID;
}

export interface TimeFrame {
  dateQuery: string[];
}

export const createFetchData = (enqueueSnackbar: any, fetchEventData: any) => {
  return async (
    query: EventRequest,
    setData: (_: any) => void,
    errorMsg: string
  ) => {
    try {
      const result = await fetchEventData(query).unwrap();
      setData(result);
    } catch (e: any) {
      const error = e?.data?.error ?? e;
      enqueueSnackbar(errorMsg + ": " + error, {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  };
};
