import { User } from "@auth0/auth0-react";
import get from "lodash/get";

let getAccessTokenSilently: Function;
let user: User;

export function getClaims() {
  return get(user, "https://443id.com/clients", {});
}

export function getPlan() {
  return get(user, "https://verosint.com/client_plan", "");
}

export function getClientUuid() {
  const claims = getClaims();
  const clients = Object.entries(claims);
  const clientUUIDs = Object.keys(claims);
  const adminClients = clients.filter(([clientUUID, roles]) => {
    return clientUUID && (roles as Array<string>).includes("Admin");
  });
  const lastClientUUID = window.localStorage?.getItem("client") as string;
  if (clientUUIDs.includes(lastClientUUID)) return lastClientUUID;
  const clientToUse = adminClients?.[0]?.[0] || clientUUIDs?.[0];
  if (clientToUse) {
    window.localStorage.setItem("client", clientToUse);
  }
  return clientToUse;
}

function getRoles() {
  const clientUUID = getClientUuid();
  return getRolesForClient(clientUUID);
}

export function getRolesForClient(clientUUID: string) {
  const claims = getClaims();
  return claims[clientUUID];
}

export function isReadOnlyForClient(clientUUID: string) {
  const roles = getRolesForClient(clientUUID);
  return roles?.includes("Read");
}

// check user's role on incoming client, redirect to root if they don't have permission
export function loadClient(clientUUID: string, path?: string) {
  const currentClient = getClientUuid();
  const nextPath = path ?? window.location.pathname + window.location.search;

  // setting to new
  window.localStorage.setItem("client", clientUUID);

  window.location.href = redirectPath(
    currentClient,
    clientUUID,
    isReadOnlyForClient(clientUUID),
    nextPath
  );
}

export const isDisallowed = () => {
  if (!isClientFree()) return false;
  const currentPath = window.location.href;
  for (const deny of disallowedFreePaths) {
    if (currentPath.includes(deny)) {
      return true;
    }
  }
  return false;
};

const disallowedFreePaths = ["/lists", "/workflows"];

export function redirectPath(
  currentClient: string,
  nextClient: string,
  readOnly: boolean,
  currentPath: string
) {
  if (currentPath == "/") {
    return currentPath;
  }

  const paths = ["/setup/", "/account/"];
  let redirectPath = currentPath.replace(currentClient, nextClient);

  //regardless of role
  if (currentPath.includes("/workflows/edit")) {
    return "/" + nextClient + "/workflows";
  }

  if (currentPath.includes("/signalprint?")) {
    return "/" + nextClient + "/signalprint";
  }

  if (currentPath.includes("/lists/")) {
    return "/" + nextClient + "/lists";
  }

  if (currentPath.includes("/accounts/account-details")) {
    return "/" + nextClient + "/accounts";
  }

  // for incoming client
  if (readOnly) {
    for (const deny of paths) {
      if (currentPath.includes(deny)) {
        redirectPath = "/";
        break;
      }
    }
  }

  return redirectPath;
}

export function loadDemo() {
  const claims = getClaims();
  const clients = Object.entries(claims);
  const readClient = clients.filter(([clientUUID, roles]) => {
    return clientUUID && (roles as Array<string>).includes("Read");
  })?.[0]?.[0];
  loadClient(readClient, "/");
}

export function isClientReadOnly() {
  const roles = getRoles();
  return roles?.includes("Read");
}

export function isClientFree() {
  return getPlan() === "free";
}

export function isClientEnterprise() {
  const plan = getPlan();

  // For now all paid plans should be considered enterprise
  const isFreeOrTrial = plan.includes("trial") || plan === "free";

  return !isFreeOrTrial;
}

// check if a feature flag is enabled for user
export function isFeatureEnabled(feature: string) {
  const featureFlags: string[] = user?.feature_flags || [];
  return featureFlags.includes(feature);
}

export const security = {
  getClientUuid,
  setUser: (u: User) => {
    user = u;
  },
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: Function) => {
    getAccessTokenSilently = func;
  },
};
