import { Box, Button, Typography } from "@mui/material";
import InteractiveDemo from "./InteractiveDemo";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material/styles";
import { useGetProfileQuery } from "src/services/443id";
import { useAuth0 } from "@auth0/auth0-react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { handleDemoNav } from "./utils";
import useDemoClientCheck from "src/hooks/useDemoClientCheck";

const OnboardingZeroState = ({
  demoUrl,
  title,
}: {
  demoUrl: string;
  title: string;
}) => {
  const { t } = useTranslation();
  const isDemo = useDemoClientCheck();
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAuthenticated } = useAuth0();
  const { data: profile } = useGetProfileQuery(undefined, {
    skip: !isAuthenticated,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pt: 4,
        pb: 8,
        overflow: "auto",
      }}
    >
      <Typography
        variant="p"
        sx={{
          fontFamily: theme.typography.h2,
          fontSize: isSM ? "20px" : "30px",
          mb: "16px",
          fontWeight: 550,
          color: theme.palette.hColor.main,
        }}
      >
        {title}
      </Typography>
      <InteractiveDemo demoUrl={demoUrl} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          maxWidth: "850px",
          mt: 4,
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="p"
            sx={{
              fontSize: "24px",
              mb: 1.2,
              fontWeight: 550,
              color: theme.palette.hColor.main,
            }}
          >
            {t("interactiveDemo.or")}
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => handleDemoNav(isDemo, profile)}
            data-testid="explore-demo-button"
            sx={{
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: "14px",
              textTransform: "none",
              padding: "10px 18.5px",
            }}
            endIcon={<ArrowForwardIcon />}
          >
            {t("interactiveDemo.exploreDemoBtn").toUpperCase()}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingZeroState;
