// @ts-nocheck
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { usePostCreateClientMutation } from "../services/443id";
import { datadogLogs } from "@datadog/browser-logs";
import { useNavigate } from "react-router-dom";

function useSetupClient() {
  const { user, isAuthenticated } = useAuth0();
  const [createClient] = usePostCreateClientMutation();
  const needsClient = isAuthenticated && !user["https://443id.com/clients"];
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) return;
    if (!needsClient) return;
    const payload = {
      company: "",
      name: "",
    };
    createClient({ payload })
      .unwrap()
      .then(() => {
        window.localStorage.setItem("newSignup", "true");
        location.reload(true);
      })
      .catch((error) => {
        datadogLogs.logger.error("client create fail:", error);
        navigate("/error");
      });
  }, [needsClient, isAuthenticated]);
}

export default useSetupClient;
