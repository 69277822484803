import { useAuth0 } from "@auth0/auth0-react";
import { useGetProfileQuery } from "../services/443id";
import { getClientUuid, isClientReadOnly } from "../helpers/security";

const get = require("lodash/get");

const refreshKey = "refresh-to-fetch-readonly-client";

/**
 * We have an Auth0 action that adds the demo client for new users
 * but the demo client is only added after auth0 issues the token
 * so we refresh the page to fetch a new token with the demo client
 * then send them to the setup page
 * Additionally, in case the auth0 action fails, we need to prevent
 * an endless redirect loop.
 * Lastly, we check if a client only has the demo environment
 * and we only send non read only users to the setup page.
 */
function useDemoClientCheck() {
  const { user, isAuthenticated } = useAuth0();
  const currentClientUUID = getClientUuid();
  const isReadonly = isClientReadOnly();
  const claims = get(user, "https://443id.com/clients", {});
  const clients = Object.keys(claims);
  const { data: profile } = useGetProfileQuery(undefined, {
    skip: !isAuthenticated,
  });
  const currentClient = profile?.clients?.filter((client) => {
    return client.clientUUID === currentClientUUID;
  })?.[0];

  const isDemo =
    currentClient?.clientUUID === "a1d3a904-0199-4bec-aeaa-fc4027e7269e" ||
    currentClient?.clientUUID === "4ab223f0-07e0-435c-96a9-ec74b47088ac";

  if (
    profile &&
    clients?.length === 1 &&
    sessionStorage.getItem(refreshKey) !== "1" &&
    !isDemo
  ) {
    sessionStorage.setItem(refreshKey, "1");
    // read only client only gets added after a refresh
    window.location.href = isReadonly ? "/" : "/setup/start";
  }

  if (clients?.length > 1) {
    sessionStorage.removeItem(refreshKey);
  }
  return isDemo;
}

export default useDemoClientCheck;
