import { Box } from "@mui/material";

export default function InteractiveDemo({ demoUrl }: { demoUrl: string }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        maxWidth: "100%",
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          position: "relative",
          paddingBottom: "calc(56.25% + 41px)",
          height: 0,
          width: "100%",
        }}
      >
        <iframe
          src={demoUrl}
          title="AI Insights | Verosint | Aug 24"
          loading="lazy"
          allowFullScreen
          allow="clipboard-write"
          data-testid="interactive-demo"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            colorScheme: "light",
            border: "none",
          }}
        />
      </Box>
    </Box>
  );
}
