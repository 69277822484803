import { loadClient } from "src/helpers/security";
import { Profile, Client } from "src/types/Profile";

export const handleDemoNav = (isDemo: boolean, profile?: Profile) => {
  const demoUUIDDev = "a1d3a904-0199-4bec-aeaa-fc4027e7269e";
  const demoUUIDProd = "4ab223f0-07e0-435c-96a9-ec74b47088ac";

  const clientNonDemoUUID = profile?.clients?.find(
    (client: Client) =>
      client.clientUUID !== demoUUIDProd && client.clientUUID !== demoUUIDDev
  )?.clientUUID;

  const clientDemoUUID = profile?.clients?.find(
    (client: Client) =>
      client.clientUUID === demoUUIDProd || client.clientUUID === demoUUIDDev
  )?.clientUUID;

  if (clientNonDemoUUID && clientDemoUUID) {
    isDemo ? loadClient(clientNonDemoUUID) : loadClient(clientDemoUUID);
  }
};
