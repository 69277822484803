// @ts-nocheck
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LinearProgress from "@mui/material/LinearProgress";

// eslint-disable-next-line no-unused-vars, react/prop-types
const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LinearProgress />,
  });
  return <Component />;
};

export default ProtectedRoute;
