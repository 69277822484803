import { RedirectLoginOptions, AppState, useAuth0 } from "@auth0/auth0-react";
import { getDeviceId } from "verosintjs";

function loginWithVerosintDeviceId(options?: RedirectLoginOptions<AppState>) {
    const { loginWithRedirect } = useAuth0();
    getDeviceId().then((id) => {
        return loginWithRedirect({ ...options, authorizationParams: { verosint_deviceid: id } });
    })
}

export { loginWithVerosintDeviceId };