import { Typography, Link } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

const PingOneSteps = () => {
  const { t } = useTranslation();

  return (
    <>
      <ol
        style={{
          margin: 0,
          padding: "18px",
        }}
        data-testid="ping-list"
      >
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.pingOneStep1">
              text
              <Link
                href="https://www.pingidentity.com/en/platform/capabilities/identity-orchestration/pingone-davinci.html"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: "none", fontWeight: "600" }}
                data-testid="pingone-davinci-link"
              ></Link>
            </Trans>
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans
              t={t}
              i18nKey="welcomeChecklist.pingOneStep2"
              components={{ bold: <strong /> }}
            />
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans
              t={t}
              i18nKey="welcomeChecklist.pingOneStep3"
              components={{ bold: <strong /> }}
            />
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans
              t={t}
              i18nKey="welcomeChecklist.pingOneStep4"
              components={{ bold: <strong /> }}
            />
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans
              t={t}
              i18nKey="welcomeChecklist.pingOneStep5"
              components={{ bold: <strong /> }}
            />
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans
              t={t}
              i18nKey="welcomeChecklist.pingOneStep6"
              components={{ bold: <strong /> }}
            />
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans
              t={t}
              i18nKey="welcomeChecklist.pingOneStep7"
              components={{ bold: <strong /> }}
            />
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.pingOneStep8">
              text
              <Link
                data-testid="pingone-connection-link"
                href="https://docs.pingidentity.com/r/en-us/davinci/davinci_adding_a_connection"
                target="_blank"
                sx={{ textDecoration: "none", fontWeight: "600" }}
              />
            </Trans>
          </Typography>
        </li>
      </ol>
    </>
  );
};

export default PingOneSteps;
