// @ts-nocheck
import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import useDocumentTitle from "src/hooks/useDocumentTitle";
import PlanDetails from "src/components/Profile/PlanDetails";
import WaterMark from "src/components/Layout/WaterMark";
import useMediaQuery from "@mui/material/useMediaQuery";
import img from "src/assets/logos/Verosint-logo-shield-color.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMetricsQuery } from "src/services/443id";
import NavBar from "src/components/Layout/NavBar";

const indexPadding = "20px !important";

const InactivePage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("account.inactive.pageTitle"));
  const navigate = useNavigate();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [openDrawer, setDrawerOpen] = useState(!isSM);
  const handleDrawerOpen = () => {
    setDrawerOpen(!openDrawer);
  };
  const { data: metricsData, isLoading } = useGetMetricsQuery({
    maxRetries: 0,
  });
  const isTrial = metricsData?.planName?.includes("trial");

  useEffect(() => {
    if (metricsData?.status === "active") {
      navigate("/");
    }
  }, [history, metricsData]);

  return (
    <Box>
      <NavBar
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        padding={indexPadding}
        showLimitExceededMessage={false}
        showUpgradeButton={isTrial ?? metricsData?.planName == "free"}
      />
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <WaterMark>
          <Card
            sx={{
              p: "24px 16px",
              maxWidth: 1000,
              margin: "auto",
              mb: 6,
              marginTop: 8,
            }}
          >
            <Grid container>
              <Grid item sx={{ minWidth: "136px" }}>
                <img alt="welcome" src={img} style={{ width: "124px" }} />
              </Grid>
              <Grid item xs={10}>
                {metricsData?.status === "canceled" && (
                  <Typography variant="h1" sx={{ mb: 2, fontWeight: 700 }}>
                    {t("account.inactive.canceled")}
                  </Typography>
                )}
                {metricsData?.status === "expired" && (
                  <Typography variant="h1" sx={{ mb: 2 }}>
                    {t("account.inactive.expired")}
                  </Typography>
                )}
                <Typography variant="p" sx={{ mb: 2 }}>
                  {t("account.inactive.msg")}
                </Typography>
                <Typography variant="p" sx={{ mr: 2, display: "inline" }}>
                  {t("account.inactive.help")}
                </Typography>
                <Button
                  href="https://verosint.com/contact-us"
                  target="_blank"
                  sx={{
                    display: "inline",
                    textTransform: "capitalize",
                  }}
                >
                  {t("account.inactive.more")}
                </Button>
              </Grid>
            </Grid>
          </Card>
          <PlanDetails />
        </WaterMark>
      )}
    </Box>
  );
};

export default InactivePage;
