// @ts-nocheck

import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import CSVUploadSelector from "../../redux/CSVUpload/selector";
import { useSelector } from "src/redux/index";
import EventsCSVUploadForm from "src/components/CSVUpload/EventsCSVUploadForm";
import useClientNavigate from "../../hooks/useClientNavigate";

const SetupUpload = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("setupPage.pageTitle"));
  const { csvFiles } = useSelector(CSVUploadSelector);
  const navigate = useClientNavigate();

  return (
    <Card sx={{ p: "24px 0px", width: 700, margin: "auto", mb: 6 }}>
      <Grid container pb={2}>
        <Grid item>
          <IconButton
            variant="text"
            component={RouterLink}
            to="/setup/info"
            aria-label="back"
            size="large"
            sx={{ mt: "-4px" }}
            data-testid="setup-back-button"
          >
            <ArrowBackIosOutlinedIcon
              color="primary"
              sx={{ fontWeight: 700, fontSize: "32px" }}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h1">{t("setupPage.uploadTitle")}</Typography>
        </Grid>
      </Grid>

      <Box sx={{ width: "75%", textAlign: "center", margin: "auto" }}>
        <EventsCSVUploadForm />
        {csvFiles?.length > 0 && (
          <Button
            sx={{ mt: 2, mb: 2 }}
            variant="contained"
            onClick={() => {
              navigate("/signalprint");
            }}
          >
            {t("global.continue")}
          </Button>
        )}
        <Typography variant="p2Sub">
          <Trans t={t} i18nKey="setupPage.uploadCSVText">
            text
            <Link
              data-testid="upload-history"
              href="https://docs.verosint.com/reference/events"
              target="_blank"
            >
              link
            </Link>{" "}
            text
          </Trans>
        </Typography>
      </Box>
    </Card>
  );
};

export default SetupUpload;
