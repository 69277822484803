import Box from "@mui/material/Box";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "src/redux/index";
import MetricsSelector from "src/redux/Metrics/selector";
import { useDispatch } from "react-redux";
import { setShowLimitExceededMessage } from "src/redux/User/slice";
import { useEffect } from "react";

const MonthlyLimitsBanner = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const selector = useSelector(MetricsSelector);

  const data = selector?.metricsData;

  const isLimited =
    data?.planName == "enterprise_trial" || data?.planName == "free";

  const isMauExceeded =
    data && data?.monthlyActiveUsers > data?.monthlyActiveUsersLimit;
  const isRequestLimitExceeded =
    data && data?.requestCount > data?.requestLimit;

  const limitsExceeded = isLimited && (isMauExceeded || isRequestLimitExceeded);

  useEffect(() => {
    dispatch(setShowLimitExceededMessage(limitsExceeded));
  }, [limitsExceeded]);

  return (
    <Box>
      {limitsExceeded && (
        <Box
          sx={{
            height: "40px",
            backgroundColor: theme.palette.accent.main,
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ height: "36px" }}
          >
            <Grid item>
              <Typography
                sx={{ display: "inline" }}
                fontSize="18px"
                color="white"
              >
                <Trans
                  t={t}
                  i18nKey="navBar.limitExceeded"
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default MonthlyLimitsBanner;
