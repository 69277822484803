import {
  Box,
  Stack,
  Typography,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
  Paper,
  Button,
} from "@mui/material";
import Grow from "@mui/material/Grow";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { KeyboardArrowDown } from "@mui/icons-material";
import Auth0Steps from "./Auth0Steps";
import PingOneSteps from "./PingOneSteps";
import OtherSteps from "./OtherSteps";
import OktaSteps from "./OktaWICSteps";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useSelector } from "src/redux/index";
import { useDispatch } from "react-redux";
import UserSelector from "src/redux/User/selector";
import { setHideWelcomeChecklist } from "src/redux/User/slice";
import "./WelcomeChecklist.scss";

const WelcomeChecklist = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const iamOptions = [
    "Auth0 by Okta",
    "Okta Workforce Identity Cloud",
    "PingOne DaVinci",
    "Other",
  ];
  const [iamProvider, setIamProvider] = useState("Auth0 by Okta");
  const { hasNoEvents, hideWelcomeChecklist } = useSelector(UserSelector);
  const [bounce, setBounce] = useState(false);

  const welcomeChecklistStyle = {
    position: "fixed",
    top: isSM ? "60px" : "unset",
    bottom: isSM ? "unset" : 70,
    right: isSM ? "unset" : 28,
    width: isSM ? "100vw" : isMD ? "400px" : "25rem",
    height: isSM ? "calc(100vh - 60px)" : isMD ? "520px" : "600px",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
  };

  const headerStyle = {
    p: 2,
    pb: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundColor: theme.palette.verosintNavy.main,
  };

  const contentStyle = {
    flexGrow: 1,
    overflowY: "auto",
    p: 2,
    pt: 0,
    pb: isSM ? "80px" : "",
  };

  const handleToggle = () => {
    dispatch(setHideWelcomeChecklist(!hideWelcomeChecklist));
  };

  // animation trigger for bounce effect once every 45 seconds
  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (!hideWelcomeChecklist) {
      intervalId = setInterval(() => {
        setBounce(true);
        setTimeout(() => {
          setBounce(false);
        }, 1000);
      }, 45000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [hideWelcomeChecklist]);

  const onChange = (event: SelectChangeEvent) => {
    setIamProvider(event.target.value);
  };

  return (
    <>
      {hasNoEvents && (
        <>
          <Button
            onClick={handleToggle}
            variant="contained"
            data-testid="checklist-toggle-button"
            startIcon={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  transform: isSM ? "scale(1.5)" : "scale(1.2)",
                  right: isSM ? "-5px" : "0px",
                  position: "relative",
                }}
              >
                <CheckCircleOutlineIcon color="secondary" />
              </Box>
            }
            sx={{
              zIndex: 5,
              position: "fixed",
              bottom: "16px",
              right: "26px",
              backgroundColor: theme.palette.highlight.main,
              borderRadius: isSM ? "50%" : "30px",
              padding: isSM ? "0px" : "8px 24px",
              minWidth: isSM ? "unset" : "auto",
              width: isSM ? "50px" : "auto",
              height: isSM ? "50px" : "auto",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#00B0E8",
              },
            }}
          >
            {!isSM && (
              <Typography
                color="secondary"
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {t("welcomeChecklist.welcomeBtn")}
              </Typography>
            )}
          </Button>
          <Grow
            in={!hideWelcomeChecklist}
            style={{ transformOrigin: "108% 110%" }}
          >
            <Paper
              sx={welcomeChecklistStyle}
              elevation={4}
              className={bounce ? "bounce" : ""}
            >
              <Box sx={headerStyle}>
                <Typography
                  variant="h3"
                  sx={{
                    pb: 2.3,
                    color: theme.palette.cardBackground.default,
                    mt: "-2px",
                  }}
                  data-testid="welcome-checklist-title"
                >
                  {t("welcomeChecklist.title")}
                </Typography>
                <IconButton
                  onClick={handleToggle}
                  sx={{
                    color: theme.palette.border.default,
                    mt: "-10px",
                  }}
                  data-testid="checklist-close-button"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box sx={contentStyle}>
                <Stack
                  direction="column"
                  justifyContent="left"
                  alignItems="left"
                >
                  <Typography
                    variant="p"
                    sx={{ mt: 1.5, mb: 2 }}
                    data-testid="welcome-checklist-subtitle1"
                  >
                    {t("welcomeChecklist.subtitle1")}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }} variant="body1">
                    {t("welcomeChecklist.selectIAM")}
                  </Typography>
                  <Select
                    data-testid="checklist-select-value"
                    size="small"
                    value={iamProvider || ""}
                    sx={{
                      borderRadius: "8px",
                      zIndex: 0,
                      mt: 1,
                      mb: 0.5,
                    }}
                    onChange={onChange}
                    displayEmpty={true}
                    renderValue={(value: any) => {
                      if (!value)
                        return <Typography>{t("global.value")}</Typography>;
                      return value;
                    }}
                    IconComponent={KeyboardArrowDown}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    {iamOptions?.map((v, i) => {
                      const props = i === 0 ? { autoFocus: true } : {};
                      return (
                        <MenuItem key={v} value={v} {...props}>
                          {v}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {iamProvider === "Auth0 by Okta" && <Auth0Steps />}
                  {iamProvider === "Okta Workforce Identity Cloud" && (
                    <OktaSteps />
                  )}
                  {iamProvider === "PingOne DaVinci" && <PingOneSteps />}
                  {iamProvider === "Other" && <OtherSteps />}
                </Stack>
              </Box>
            </Paper>
          </Grow>
        </>
      )}
    </>
  );
};
export default WelcomeChecklist;
