// @ts-nocheck
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import WaterMark from "src/components/Layout/WaterMark";
import { Trans, useTranslation } from "react-i18next";
import EmailCheckIcon from "src/pages/error/EmailCheckIcon";

const AlreadyVerifiedPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleContinue = () => {
    navigate("/");
  };

  return (
    <Box>
      <WaterMark>
        <Card
          style={{
            margin: "auto",
            marginBottom: "8rem",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Stack
            spacing={2}
            alignItems="center"
            style={{
              textAlign: "center",
              paddingLeft: "10rem",
              paddingRight: "10rem",
              paddingTop: "4rem",
              paddingBottom: "3rem",
            }}
          >
            <EmailCheckIcon
              style={{
                fontSize: "48px",
                marginBottom: "2rem",
              }}
            />
            <Typography
              variant="h1"
              style={{
                fontWeight: 700,
                marginTop: "3rem",
                marginBottom: "1rem",
              }}
            >
              <Trans t={t} i18nKey="alreadyVerifiedEmail.title">
                text<sup style={{ fontSize: "24px" }}>&trade;</sup>
              </Trans>
            </Typography>
            <Typography
              variant="p2"
              style={{ marginTop: 0, marginBottom: "1rem" }}
              maxWidth={300}
            >
              {t("alreadyVerifiedEmail.message1")}
            </Typography>
            <Button
              variant="contained"
              sx={{ fontWeight: "700" }}
              onClick={handleContinue}
            >
              {t("global.continue")}
            </Button>
          </Stack>
        </Card>
      </WaterMark>
    </Box>
  );
};

export default AlreadyVerifiedPage;
