import { LinearProgress } from "@mui/material";
import { loginWithVerosintDeviceId } from "src/helpers/deviceid";

const RedirectToLogin = () => {
  const returnTo = window.location.pathname + window.location.search;
  loginWithVerosintDeviceId({ appState: { returnTo } });
  return <LinearProgress />;
};

export default RedirectToLogin;
