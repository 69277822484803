// @ts-nocheck
/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import i18n from "../../i18n/i18n";

const initialState = {
  name: {
    first: "",
    last: "",
  },
  email: "",
  hasNoEvents: false,
  hideWelcomeChecklist: true,
  bounceChecklist: false,
  showLimitExceededMsg: false,
  organization: "",
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessages: [],
  navPath: "",
  session: null,
  currentOption: "",
  highRisk: "0.8",
  mediumRisk: "0.3",
  lowRisk: "0.3",
  loginURL: "",
  isLoggedIn: null,
  csApiToken: null,
  auth0Token: null,
  clientUuid: null,
  openDiscardModal: false,
  playgroundPolicy: {
    playGuid: null,
    policyName: i18n.t("playground.defaultPolicyName"),
    weightSet: null,
  },
};

const UserSlice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    updateNavPath: (state, action) => {
      state.navPath = action.payload;
    },
    setHasNoEvents: (state, action) => {
      state.hasNoEvents = action.payload;
    },
    setHideWelcomeChecklist: (state, action) => {
      state.hideWelcomeChecklist = action.payload;
    },
    setShowLimitExceededMessage: (state, action) => {
      state.showLimitExceededMsg = action.payload;
    },
    updateCurrentOption: (state, action) => {
      state.currentOption = action.payload;
    },
    getUser: (state) => {
      state.isFetching = true;
    },
    updateAccessToken: (state, action) => {
      state.session = action.payload.session;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setLoginURL: (state, action) => {
      state.loginURL = action.payload;
    },
    setLogoutURL: (state, action) => {
      state.logoutURL = action.payload;
    },
    setHighRisk: (state, action) => {
      state.highRisk = action.payload;
    },
    setMediumRisk: (state, action) => {
      state.mediumRisk = action.payload;
    },
    setLowRisk: (state, action) => {
      state.lowRisk = action.payload;
    },
    resetRiskScore: (state) => {
      state.highRisk = "0.8";
      state.mediumRisk = "0.2";
      state.lowRisk = "0.2";
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setClientUuid: (state, action) => {
      state.clientUuid = action.payload;
    },
    updateCSAPIToken: (state, action) => {
      state.csApiToken = action.payload;
    },
    updateAuth0Token: (state, action) => {
      state.auth0Token = action.payload;
    },
    updatePlayground: (state, action) => {
      state.playgroundPolicy = action.payload;
    },
    setOpenDiscardModal: (state, action) => {
      state.openDiscardModal = action.payload;
    },
    logoutUser: () => initialState,
  },
});

export const {
  updateNavPath,
  setHasNoEvents,
  setCustomOutcomes,
  setHideWelcomeChecklist,
  setShowLimitExceededMessage,
  getUser,
  updateAccessToken,
  updateCSAPIToken,
  updateAuth0Token,
  getSession,
  logoutUser,
  updatePlayground,
  updateCurrentOption,
  setIsLoggedIn,
  setLoginURL,
  setLogoutURL,
  setHighRisk,
  setMediumRisk,
  setLowRisk,
  resetRiskScore,
  setUser,
  setOpenDiscardModal,
  setClientUuid,
} = UserSlice.actions;

export default UserSlice.reducer;
