import { Box } from "@mui/material";
import { CSSProperties, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import shieldLogo from "../../assets/logos/verosint-shield-navy.svg";
import styles from "./styles";

type Props = {
  children: ReactNode;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  fullWidth?: boolean;
};

const PageSection = ({
  children,
  fullWidth = false,
  style = {},
  containerStyle,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Box id="page-section" sx={{ position: "relative", ...style }}>
      <Box sx={styles}>
        <img
          src={shieldLogo}
          draggable="false"
          style={{
            height: "650px",
          }}
          alt={t("console.logoAlt")}
        />
      </Box>
      <Box
        sx={{
          maxWidth: fullWidth ? "100%" : "1200px",
          minWidth: fullWidth ? "85vw" : "inherit",
          p: "24px",
          position: "relative",
          zIndex: 2,
          ...containerStyle,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageSection;
