/* istanbul ignore file */
import type { Row } from "src/components/Accounts/AccountsTable";
import { createSlice } from "@reduxjs/toolkit";

interface State {
  accounts: Row[];
  search: string;
}

const initialState: State = {
  accounts: [],
  search: "",
};

const AccountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setAccounts, setSearch } = AccountsSlice.actions;
export default AccountsSlice.reducer;
