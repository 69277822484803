import { Box, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import shieldLogo from "../../assets/logos/verosint-shield-navy.svg";
import Footer from "./Footer";
import styles from "./styles";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const WaterMark = ({ children }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{
          minHeight: "calc(100vh - 56px)",
        }}
      >
        <Grid alignItems="center" justifyContent="center" mt={6}>
          <Box sx={styles}>
            <img
              src={shieldLogo}
              draggable="false"
              style={{
                height: "650px",
              }}
              alt={t("console.logoAlt")}
            />
          </Box>
          <Box
            sx={{
              maxWidth: 1200,
              margin: "auto",
              position: "relative",
              zIndex: 2,
            }}
          >
            {children}
          </Box>
        </Grid>
      </Stack>
      <Footer />
    </Box>
  );
};

export default WaterMark;
