// @ts-nocheck
import {
  LockPersonOutlined,
  VerifiedUserOutlined,
  FormatListBulletedOutlined,
  RuleOutlined,
  LinearScaleOutlined,
  GroupsOutlined,
  PhoneOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PlanDetails = () => {
  const { t } = useTranslation();

  const flexText = {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  };
  const fontSize = "30px";

  return (
    <Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h2">
          {t("account.upgrade.enterprisePlan")}
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        pl={6}
        pr={6}
        pt={4}
        mb={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6} md={3}>
          <div style={flexText}>
            <LockPersonOutlined color="hColor" sx={{ fontSize, mr: 2 }} />
            <Typography
              sx={{ display: "inline" }}
              variant="h4Dark"
              color="secondary"
            >
              {t("account.upgrade.adaptiveProofing")}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={6} md={3.5}>
          <div style={flexText}>
            <RuleOutlined color="hColor" sx={{ fontSize, mr: 2 }} />
            <Typography
              sx={{ display: "inline" }}
              variant="h4Dark"
              color="secondary"
            >
              {t("account.upgrade.realTimeWorkflow")}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={6} md={3}>
          <div style={flexText}>
            <FormatListBulletedOutlined
              color="hColor"
              sx={{ fontSize, mr: 2 }}
            />
            <Typography
              sx={{ display: "inline" }}
              variant="h4Dark"
              color="secondary"
            >
              {t("account.upgrade.lists")}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={6} md={2.5}>
          <div style={flexText}>
            <PhoneOutlined color="hColor" sx={{ fontSize, mr: 2 }} />
            <Typography
              sx={{ display: "inline" }}
              variant="h4Dark"
              color="secondary"
            >
              {t("account.upgrade.phoneSignals")}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={6} md={3}>
          <div style={flexText}>
            <LinearScaleOutlined color="hColor" sx={{ fontSize, mr: 2 }} />
            <Typography
              sx={{ display: "inline" }}
              variant="h4Dark"
              color="secondary"
            >
              {t("account.upgrade.unlimitedEvents")}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={6} md={4}>
          <div style={flexText}>
            <GroupsOutlined color="hColor" sx={{ fontSize, mr: 2 }} />
            <Typography
              sx={{ display: "inline" }}
              variant="h4Dark"
              color="secondary"
            >
              {t("account.upgrade.customMAU")}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={6} md={2.5}>
          <div style={flexText}>
            <FileDownloadOutlined color="hColor" sx={{ fontSize, mr: 2 }} />
            <Typography
              sx={{ display: "inline" }}
              variant="h4Dark"
              color="secondary"
            >
              {t("account.upgrade.dataDownloads")}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={6} md={2.5}>
          <div style={flexText}>
            <VerifiedUserOutlined color="hColor" sx={{ fontSize, mr: 2 }} />
            <Typography
              sx={{ display: "inline" }}
              variant="h4Dark"
              color="secondary"
            >
              {t("account.upgrade.enterpriseSSO")}
              <sup>&trade;</sup>
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        pl={4}
        pr={4}
        pt={4}
        pb={4}
        justifyContent="center"
      >
        <Grid item>
          <Card
            sx={{ padding: "24px", textAlign: "center", minWidth: "376px" }}
          >
            <Typography variant="h2Dark" sx={{ mb: 2 }}>
              {t("account.apiUsage.enterprisePlan")}
            </Typography>
            <Typography variant="h1" fontSize="48px" color="primary">
              {t("account.upgrade.enterpriseCalls")}
            </Typography>
            <Typography variant="pSub" fontWeight={700} sx={{ mb: 6 }}>
              {t("account.upgrade.enterpriseCallsLabel")}
            </Typography>
            <Button
              variant="contained"
              sx={{ fontWeight: 700, fontSize: "18px", mb: "69px" }}
              href="https://verosint.com/contact-us"
              target="_blank"
            >
              {t("account.upgrade.contactUs")}
            </Button>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ textAlign: "left" }}>
              <Typography variant="h5Dark" sx={{ display: "block" }}>
                {t("account.upgrade.includesLabel")}
              </Typography>
              <Typography variant="p2">
                {t("account.upgrade.tailoredOnBoarding")}
              </Typography>
              <Typography variant="p2">
                {t("account.upgrade.unlimitedSeats")}
              </Typography>
              <Typography variant="p2">
                {t("account.upgrade.enterpriseSupport")}
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlanDetails;
