import { Grid, Typography, Box, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "src/hooks/useDocumentTitle";
import HeaderSection from "src/components/Layout/HeaderSection";
import PageSection from "src/components/Layout/PageSection";
import AccountsTable from "src/components/Accounts/AccountsTable";
import AccountSearchbar from "src/components/Accounts/AccountSearchbar";
import { useSelector } from "src/redux/index";
import UserSelector from "src/redux/User/selector";
import OnboardingZeroState from "src/components/ZeroEventsState/OnboardingZeroState";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const AccountsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const demoUrl =
    "https://demo.arcade.software/QNa1IpEfQhAEZVlUOUoH?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true";
  const { hasNoEvents } = useSelector(UserSelector);
  useDocumentTitle(t("accountDetails.accounts"));

  return (
    <Box sx={{ padding: "0!important" }}>
      <HeaderSection
        style={{ paddingBottom: hasNoEvents ? 3.5 : 2, minWidth: "450px" }}
      >
        <Grid
          container
          direction="row"
          sx={{ pb: 2 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h1"
              noWrap
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {t("accountDetails.accounts")}
            </Typography>
          </Grid>
        </Grid>
      </HeaderSection>
      <PageSection
        fullWidth={!isSM}
        containerStyle={hasNoEvents ? { paddingTop: 0 } : {}}
      >
        {!hasNoEvents ? (
          <Grid item xs={12}>
            <Card>
              <AccountSearchbar />
              <AccountsTable />
            </Card>
          </Grid>
        ) : (
          <OnboardingZeroState
            demoUrl={demoUrl}
            title={t("interactiveDemo.accountsTitle")}
          />
        )}
      </PageSection>
    </Box>
  );
};

export default AccountsPage;
