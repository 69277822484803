import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RuleType, WorkflowType } from "src/types/Workflow";

export interface ListType {
  id: string;
  name: string;
}

interface RiskType {
  name: string;
  label: string;
}

// Defining the initial state with the correct type annotations
interface InitialState {
  // Current version of rule array, including edits that have not been saved
  rules: RuleType[];
  // Mapping of list uuid to list name. Uuid is stored in rules, but we want to display the name
  lists: ListType[];
  // Mapping of Risks to display a non-technical label in dropdown
  risks: RiskType[];
  isExport: boolean;
  customOutcomes: string[];
  outcomesFetched: boolean;
  isRuleEdited: boolean;
  invalidWorkflow: boolean;
  exportItems: WorkflowType[];
  evaluator: {
    email: string;
    ip: string;
    phone: string;
    userAgent: string;
    accountId: string;
  };
  defaultOutcome: string;
  openDeleteRuleModal: boolean;
  openDeleteWorkflowModal: boolean;
  openImportWorkflowModal: boolean;
  cursorPosition: {
    row: number | undefined;
    column: number | undefined;
    source: string;
    uuid: string;
  };
  workflowToDelete: WorkflowType;
  isRuleModalOpen: boolean;
  isAdd: boolean;
}

const initialState: InitialState = {
  isExport: false,
  customOutcomes: [],
  outcomesFetched: false,
  isRuleEdited: false,
  invalidWorkflow: false,
  exportItems: [],
  evaluator: {
    email: "",
    ip: "",
    phone: "",
    userAgent: "",
    accountId: "",
  },
  rules: [],
  defaultOutcome: "ALLOW",
  openDeleteRuleModal: false,
  openImportWorkflowModal: false,
  cursorPosition: {
    row: undefined,
    column: undefined,
    source: "query",
    uuid: "1",
  },
  openDeleteWorkflowModal: false,
  workflowToDelete: { name: "", rules: [] },
  lists: [],
  risks: [],
  isRuleModalOpen: false,
  isAdd: true, // True = adding a rule, False = editing a rule
};

const WorkflowSlice = createSlice({
  name: "WorkflowSlice",
  initialState,
  reducers: {
    setIsExport: (state, action: PayloadAction<boolean>) => {
      state.isExport = action.payload;
    },
    setCustomOutcomes: (state, action: PayloadAction<string[]>) => {
      state.customOutcomes = action.payload;
    },
    setOutcomesFetched: (state, action: PayloadAction<boolean>) => {
      state.outcomesFetched = action.payload;
    },
    setExportItems: (state, action: PayloadAction<WorkflowType[]>) => {
      state.exportItems = action.payload;
    },
    setDefaultOutcome: (state, action: PayloadAction<string>) => {
      state.defaultOutcome = action.payload;
    },
    setInvalidWorkflow: (state, action: PayloadAction<boolean>) => {
      state.invalidWorkflow = action.payload;
    },
    setIsRuleEdited: (state, action: PayloadAction<boolean>) => {
      state.isRuleEdited = action.payload;
    },
    setEvaluator: (state, action: PayloadAction<InitialState["evaluator"]>) => {
      state.evaluator = action.payload;
    },
    setRules: (state, action: PayloadAction<RuleType[]>) => {
      state.rules = action.payload;
    },
    setOpenDeleteRuleModal: (state, action: PayloadAction<boolean>) => {
      state.openDeleteRuleModal = action.payload;
    },
    setOpenDeleteWorkflowModal: (state, action: PayloadAction<boolean>) => {
      state.openDeleteWorkflowModal = action.payload;
    },
    setOpenImportWorkflowModal: (state, action: PayloadAction<boolean>) => {
      state.openImportWorkflowModal = action.payload;
    },
    setWorkflowToDelete: (state, action: PayloadAction<WorkflowType>) => {
      state.workflowToDelete = action.payload;
    },
    setLists: (state, action: PayloadAction<ListType[]>) => {
      state.lists = action.payload;
    },
    setRisks: (state, action: PayloadAction<RiskType[]>) => {
      state.risks = action.payload;
    },
    setIsRuleModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isRuleModalOpen = action.payload;
    },
    setIsAdd: (state, action: PayloadAction<boolean>) => {
      state.isAdd = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setIsExport,
  setCustomOutcomes,
  setOutcomesFetched,
  setExportItems,
  setInvalidWorkflow: setInvalidRuleSet,
  setIsRuleEdited,
  setDefaultOutcome,
  setRules,
  setLists,
  setRisks,
  setEvaluator,
  setOpenDeleteRuleModal,
  setOpenDeleteWorkflowModal,
  setOpenImportWorkflowModal,
  setWorkflowToDelete,
  setIsRuleModalOpen,
  setIsAdd,
  reset,
} = WorkflowSlice.actions;

export default WorkflowSlice.reducer;
