import { useTranslation } from "react-i18next";
import { Box, Grid, Stack, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { setSearch } from "src/redux/Accounts/slice";
import AccountsSelector from "src/redux/Accounts/selector";
import { useSelector } from "src/redux/index";

const AccountSearchbar = () => {
  const { t } = useTranslation();
  const { search } = useSelector(AccountsSelector);
  const dispatch = useDispatch();
  const handleSearch = (value: string) => dispatch(setSearch(value));

  return (
    <Box id="signalprint-search-box">
      <Stack>
        <Grid container spacing={0}>
          <Grid item sx={{ width: "550px", ml: 3, mt: 3 }}>
            <TextField
              fullWidth
              onChange={(event) => handleSearch(event.target.value)}
              size="small"
              label={t("accountDetails.searchDescription")}
              InputProps={{
                value: search,
                endAdornment: (
                  <InputAdornment position="end" style={{ marginRight: 0 }}>
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default AccountSearchbar;
