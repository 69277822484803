// @ts-nocheck
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import sidebarLogo from "src/assets/logos/Verosint-logo-h-on-dark.svg";

const Footer = () => {
  const theme = useTheme();

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: 2,
        pt: 1,
        pb: 1,
        mt: "auto",
        backgroundColor: theme.palette.secondary.main,
        color: "white",
      }}
    >
      <Container maxWidth="xlg">
        <Grid container direction="row" alignItems="center">
          <Grid
            item
            justifyContent="flex-start"
            alignItems="center"
            sx={{ heght: "48px", mr: 1 }}
          >
            <Typography variant="p" sx={{ color: "white" }}>
              {t("footer.secured")}&nbsp;
            </Typography>
          </Grid>
          <Grid item alignItems="center">
            <img
              style={{ width: "127px", mt: "5px" }}
              alt={t("footer.logoAlt")}
              src={sidebarLogo}
            />
          </Grid>
          <Grid justifyContent="flex-end" ml="auto" display="flex">
            <Typography variant="p" sx={{ color: "white", mr: 2 }}>
              {t("footer.help")}
            </Typography>
            <Link
              sx={{
                color: "white",
                textDecorationColor: "white",
                "&:hover": {
                  textDecorationColor: "#047291",
                  color: "#047291",
                },
              }}
              href="https://verosint.com/contact-us"
              target="_blank"
            >
              {t("footer.support")}
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
