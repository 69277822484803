// This Filter type def is used exclusively for Explorer
// Workflow request requires different values as we build the payload

export interface Filter {
  filters?: Filter[];
  /**
   * The field to filter by
   */
  field?: string;
  /**
   * The operator to apply
   */
  operator: OperatorEnum;
  /**
   * The values to filter
   */
  values?: string[];
}

export enum OperatorEnum {
  Between = "between",
  Contains = "contains",
  EndsWith = "endsWith",
  Equals = "equals",
  Gt = "gt",
  Gte = "gte",
  LTE = "lte",
  Lt = "lt",
  NotContains = "notContains",
  NotEquals = "notEquals",
  StartsWith = "startsWith",
  Or = "or",
  And = "and",
  Exists = "exists",
  NotExists = "notExists",
}
