// @ts-nocheck
/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import filterOptions from "./filterOptions";

const initialState = {
  printCount: 0,
  timeFrame: filterOptions[0],
  graphTimeRange: [],
  customRanges: [
    {
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      key: "selection",
    },
  ],
};

const DashboardsSlice = createSlice({
  name: "dashboards",
  initialState,
  reducers: {
    setPrintCount: (state, action) => {
      state.printCount = action.payload;
    },
    setTimeFrame: (state, action) => {
      state.timeFrame = action.payload;
    },
    setGraphTimeRange: (state, action) => {
      state.graphTimeRange = action.payload;
    },
    setCustomRanges: (state, action) => {
      state.customRanges = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setPrintCount,
  setTimeFrame,
  setCustomRanges,
  setGraphTimeRange,
  reset,
} = DashboardsSlice.actions;

export default DashboardsSlice.reducer;
