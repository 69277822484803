// @ts-nocheck
/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  notifications: [],
};

const notistack = createSlice({
  name: "notistack",
  initialState,
  reducers: {
    enqueueSnackbar: {
      prepare: (notification) => ({
        payload: {
          ...notification,
          key: notification.key ?? uuidv4(),
          options: {
            ...notification.options,
          },
        },
      }),
      reducer: (state, { payload }) => {
        state.notifications.push(payload);
      },
    },
    closeSnackbar: {
      prepare: (key) => ({
        payload: {
          dismissAll: !key,
          key,
        },
      }),
      reducer: (state, { payload }) => {
        state.notifications = state.notifications.map((notification) => {
          if (payload.dismissAll || notification.key === payload.key) {
            return { ...notification, dismissed: true };
          }
          return { ...notification };
        });
      },
    },
    removeSnackbar: (state, { payload }) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== payload
      );
    },
  },
});

export const {
  enqueueSnackbar: enqueueSnackbarAction,
  closeSnackbar: closeSnackbarAction,
  removeSnackbar,
} = notistack.actions;

export default notistack.reducer;
