// @ts-nocheck
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Footer from "src/components/Layout/Footer";
import useDocumentTitle from "src/hooks/useDocumentTitle";
import spikeTarPit from "../../assets/spike-tar-pit.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ErrorPage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("error.pageTitle"));
  const searchParams = new URLSearchParams(window.location.search);
  const error = searchParams.get("error");
  const errorDescription = searchParams.get("error_description");
  const navigate = useNavigate();

  useEffect(() => {
    if (errorDescription === "This URL can be used only once") {
      navigate("/used-link");
    } else if (errorDescription === "This account is already verified.") {
      navigate("/already-verified");
    } else if (errorDescription === "Verification required.") {
      navigate("/verification-required");
    } else if (errorDescription === "Access expired.") {
      navigate("/verification-expired");
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
      spacing={0}
    >
      <Container maxWidth="md">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          sx={{ mt: 6 }}
        >
          <Grid item>
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: 72, sm: 96, md: 124 } }}
            >
              {t("error.title")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h1" fontWeight="bold">
              {t("error.subtitle")}
            </Typography>
          </Grid>
          <Grid item sx={{ mt: 3, mb: 3 }}>
            <ErrorOutlineOutlinedIcon
              sx={{ fontSize: 112, margin: "9px 0" }}
              color="error"
            />
          </Grid>
          <Grid item>
            <Typography variant="h2">
              {t("error.message1")} {t("error.message2")}
            </Typography>
          </Grid>
          {error && (
            <Grid item sx={{ mt: 2 }}>
              <Typography variant="p">
                {error}: {errorDescription}
              </Typography>
            </Grid>
          )}
          <Grid item sx={{ mt: 4 }}>
            <img width="100%" alt={t("error.dinoAlt")} src={spikeTarPit} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default ErrorPage;
