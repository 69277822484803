// @ts-nocheck
import { Box, Card, Stack, Typography } from "@mui/material";
import WaterMark from "src/components/Layout/WaterMark";
import { Trans, useTranslation } from "react-i18next";
import EmailErrorIcon from "src/pages/error/EmailErrorIcon";

const VerificationExpiredPage = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <WaterMark>
        <Card
          style={{
            margin: "auto",
            marginBottom: "6rem",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Stack
            spacing={2}
            alignItems="center"
            style={{
              textAlign: "center",
              paddingLeft: "8rem",
              paddingRight: "8rem",
              paddingTop: "4rem",
              paddingBottom: "5rem",
            }}
          >
            <EmailErrorIcon
              style={{
                fontSize: "48px",
                marginBottom: "2rem",
              }}
            />
            <Typography
              variant="h1"
              style={{
                marginBottom: "2rem",
                marginTop: "2rem",
              }}
            >
              <Trans t={t} i18nKey="verificationExpired.title">
                text<sup style={{ fontSize: "24px" }}>&trade;</sup>
              </Trans>
            </Typography>
            <Typography
              variant="p2"
              style={{
                marginBottom: 0,
                maxWidth: "300px",
                margin: "0 auto",
              }}
            >
              {t("verificationExpired.message1")}
              <b>{t("verificationExpired.message2")}</b>
            </Typography>
          </Stack>
        </Card>
      </WaterMark>
    </Box>
  );
};

export default VerificationExpiredPage;
