import { Link, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useClientNavigate from "src/hooks/useClientNavigate";

const Auth0Steps = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useClientNavigate();
  return (
    <>
      <ol
        style={{
          margin: 0,
          padding: "18px",
        }}
        data-testid="auth0-list"
      >
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="p">
            <Trans t={t} i18nKey="welcomeChecklist.auth0Step1">
              text
              <Link
                sx={{ textDecoration: "none", fontWeight: "600" }}
                data-testid="auth0-link"
                href="https://auth0.com/"
                target="_blank"
              >
                link
              </Link>{" "}
              text
            </Trans>
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="p">
            <Trans t={t} i18nKey="welcomeChecklist.auth0Step2"></Trans>
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="p">
            <Trans
              t={t}
              i18nKey="welcomeChecklist.auth0Step3"
              components={{
                1: (
                  <span
                    onClick={() => {
                      navigator.clipboard
                        .writeText(
                          "https://api.verosint.com/v1/signalprint/logs"
                        )
                        .then(() => {
                          enqueueSnackbar(t("global.copied"), {
                            variant: "success",
                            autoHideDuration: 4000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                          });
                        })
                        .catch(() => {
                          enqueueSnackbar(t("global.copyFailed"), {
                            variant: "error",
                            autoHideDuration: 4000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                          });
                        });
                    }}
                    style={{ cursor: "pointer", fontWeight: "600" }}
                  />
                ),
              }}
            ></Trans>
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="p">
            <Trans t={t} i18nKey="welcomeChecklist.auth0Step4">
              text
              <Link
                onClick={() => navigate("/account/api-keys", {})}
                sx={{
                  textDecoration: "none",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              ></Link>
            </Trans>
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.auth0Step5_part1" />
          </Typography>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.auth0Step5_part2" />
          </Typography>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.auth0Step5_part3" />
          </Typography>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.auth0Step5_part4" />
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="p">
            <Trans t={t} i18nKey="welcomeChecklist.auth0Step6"></Trans>
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="p">
            <Trans t={t} i18nKey="welcomeChecklist.auth0Step7">
              text
              <Link
                sx={{ textDecoration: "none", fontWeight: "600" }}
                data-testid="file-ingestion-link"
                href="https://docs.verosint.com/docs/log-file-ingestion"
                target="_blank"
              ></Link>{" "}
            </Trans>
          </Typography>
        </li>
      </ol>
    </>
  );
};

export default Auth0Steps;
