// @ts-nocheck
import React, { useCallback } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Box, Button, Stack, Typography } from "@mui/material";
import * as Papa from "papaparse";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { downloadFile } from "src/helpers/utils";
import {
  setCsvData,
  setCsvFiles,
  setCsvUploadError,
  removeFile,
} from "src/redux/CSVUpload/slice";
import CSVUploadSelector from "src/redux/CSVUpload/selector";
import { useSelector } from "src/redux/index";
import { useSnackbar } from "notistack";
import CSVErrorModal from "./CSVErrorModal";
import dayjs from "dayjs";

const columns = [
  "timestamp",
  "ip",
  "accountId",
  "email",
  "phone",
  "userAgent",
  "type",
];
const requiredFields = ["ip", "userAgent", "type"];

const EventsCSVUploadForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { csvFiles } = useSelector(CSVUploadSelector);
  const { enqueueSnackbar } = useSnackbar();

  const uploadLimit = 4500;

  function downloadCSVTemplate() {
    const data = [
      {
        timestamp: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
        ip: "1.1.1.1",
        accountId: "accountID",
        email: "bob@example.com",
        phone: "555-555-5555",
        type: "LOGIN_SUCCESS",
        // eslint-disable-next-line max-len
        userAgent:
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36",
      },
    ];
    const csv = Papa.unparse(data, { columns });
    downloadFile(
      csv,
      t("evaluator.csvTemplateFilename"),
      "text/csv;charset=utf-8;"
    );
  }

  function convertFilesToObjects(files) {
    return files.map((f) => {
      return {
        path: f.path,
        lastModified: f.lastModified,
        name: f.name,
        size: f.size,
        type: f.type,
      };
    });
  }

  function hasRequiredHeaders(fields) {
    const keys = new Set(fields);
    const intersection = new Set(requiredFields.filter((i) => keys.has(i)));
    return [...intersection];
  }

  function showErrorMsg(translateID, info) {
    const msg = info ? t(translateID) + ": " + info : t(translateID);
    enqueueSnackbar(msg, {
      variant: "error",
      autoHideDuration: 4000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => dispatch(setCsvUploadError(true));
        reader.onerror = () => dispatch(setCsvUploadError(true));
        reader.onload = () => {
          const csv = reader.result.replace(/\r\n/g, "\n");
          const res = Papa.parse(csv, {
            header: true,
            skipEmptyLines: true,
            fastMode: false,
          });
          const isEmpty = res.data?.length === 0;
          const headersFound = hasRequiredHeaders(res.meta.fields);
          const csvData = res.data.slice(0, uploadLimit);
          const oldDates = csvData.filter((d) => {
            const givenDate = dayjs(d.timestamp);
            const currentDate = dayjs();
            const differenceInDays = currentDate.diff(givenDate, "day", true);
            return differenceInDays > 31;
          });
          const tooNewDates = csvData.filter((d) => {
            const givenDate = dayjs(d.timestamp);
            const currentDate = dayjs();
            const differenceInDays = givenDate.diff(currentDate, "day", true);
            return differenceInDays > 1;
          });
          if (tooNewDates.length > 0) {
            showErrorMsg("evaluator.datesTooNew");
            return;
          }
          if (oldDates.length > 0) {
            showErrorMsg("evaluator.datesTooOld");
            return;
          }
          if (isEmpty || csvData.length < 1) {
            showErrorMsg("evaluator.csvErrorEmpty");
            return;
          }
          if (headersFound.length !== requiredFields.length) {
            const missing = requiredFields.filter(
              (field) => !headersFound.includes(field)
            );
            showErrorMsg("evaluator.csvErrorMissingFields", missing.join(","));
            return;
          }
          dispatch(setCsvFiles(convertFilesToObjects(acceptedFiles)));
          dispatch(setCsvData(csvData));
        };
        reader.readAsBinaryString(file);
      });
    },
    [uploadLimit]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [],
    },
  });

  return (
    <Stack>
      <CSVErrorModal />
      <Stack sx={{ display: csvFiles?.length > 0 ? "none" : "flex" }}>
        <Button
          data-testid="download-csv-template"
          onClick={() => downloadCSVTemplate()}
        >
          <FileDownloadOutlinedIcon color="primary" />
          {t("evaluator.downloadCSVTemplateBtn")}
        </Button>
        <Stack
          data-testid="evaluator-drop-zone"
          spacing={1}
          alignItems="center"
          {...getRootProps({ className: "dropzone" })}
        >
          <input data-testid="upload-csv-input" {...getInputProps()} />
          <CloudUploadOutlinedIcon
            sx={{ fontSize: "140px", color: "rgb(224, 224, 224)" }}
          />
          <Box pb={2}>
            <Typography>
              <Trans t={t} i18nKey="evaluator.uploadInstruction">
                Drag CSV file or
                <Button
                  data-testid="Browse-Button"
                  sx={{ pl: 0, pb: 1, textDecoration: "underline" }}
                >
                  Browse
                </Button>
                .
              </Trans>
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Box>
        <Typography variant="p2" component="p">
          {t("evaluator.uploadSubtitle", {
            count: new Intl.NumberFormat().format(uploadLimit),
          })}
        </Typography>
      </Box>

      {csvFiles?.length > 0 && (
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Box sx={{ position: "relative", padding: "40px 20px 0px" }}>
            <ClearOutlinedIcon
              sx={{
                fontSize: "24px",
                position: "absolute",
                right: "0px",
                top: "24px",
                cursor: "pointer",
              }}
              onClick={() => dispatch(removeFile())}
              color="primary"
            />
            <UploadFileOutlinedIcon sx={{ fontSize: "60px" }} color="success" />
          </Box>
          <Typography variant="h6">{t("evaluator.uploadComplete")}</Typography>
          <Typography>
            {t("evaluator.uploadCompleteMsg", { filename: csvFiles[0].name })}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default EventsCSVUploadForm;
