import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { initLoggingProps, initAnalytics } from "src/helpers/instrumentation";
import { useGetProfileQuery } from "../services/443id";
import { Metrics } from "src/types/Metrics";

function useUserData(metricsData: Metrics, skip: boolean) {
  const { user } = useAuth0();
  const { data: profileData } = useGetProfileQuery(undefined, { skip });
  useEffect(() => {
    if (!metricsData || !profileData || !user) return;
    initLoggingProps(user, profileData, metricsData);
    initAnalytics(user);
  }, [metricsData, user, profileData]);
}

export default useUserData;
