// @ts-nocheck
import { useAuth0 } from "@auth0/auth0-react";
import { React } from "react";
import ErrorPage from "src/pages/error/ErrorPage";
import UsedEmailVerificationPage from "src/pages/error/UsedEmailVerificationPage";
import AlreadyVerifiedPage from "src/pages/error/AlreadyVerifiedPage";
import VerificationRequiredPage from "src/pages/error/VerificationRequiredPage";
import VerificationExpiredPage from "src/pages/error/VerificationExpiredPage";
import InactivePage from "src/pages/account/Inactive/InactivePage";
import { datadogLogs } from "@datadog/browser-logs";
import useDemoClientCheck from "src/hooks/useDemoClientCheck";
import { security } from "src/helpers/security";
import useStripe from "src/hooks/useStripe";
import { useGetMetricsQuery } from "src/services/443id";
import AppRouter from "./AppRouter";
import { LinearProgress } from "@mui/material";
import useSetupClient from "src/hooks/useSetupClient";
import useClientSwitcher from "src/hooks/useClientSwitcher";
import { useGetProfileQuery } from "../services/443id";
import useUserData from "src/hooks/useUserData";

const err = (msg: string) => {
  return `Error: ${msg}`;
};

const RouteWrapper = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const needsClient = isAuthenticated && !user["https://443id.com/clients"];
  const skip = needsClient || !isAuthenticated;
  const {
    data: metricsData,
    isLoading: metricsLoading,
    error: metricsError,
  } = useGetMetricsQuery(undefined, { skip, maxRetries: 0 });
  const { data: profile, isLoading: isLoadingProfile } = useGetProfileQuery(
    undefined,
    { skip }
  );
  const loadingStripe = useStripe(metricsData);
  const loading =
    isLoading || metricsLoading || isLoadingProfile || loadingStripe;
  const switchingClient = useSetupClient();
  useDemoClientCheck();
  useClientSwitcher(profile);
  useUserData(metricsData, skip);

  if (isAuthenticated) {
    security.setAccessTokenSilently(getAccessTokenSilently);
    security.setUser(user);
  }

  if (metricsError?.status == 402) {
    return <InactivePage />;
  }

  if (window.location.pathname === "/logout") {
    logout();
  }

  if (metricsError) {
    datadogLogs.logger.error(
      "Fetch metrics error:",
      JSON.stringify(metricsError)
    );
    throw new Error("Fetch metrics error:", JSON.stringify(metricsError));
  }

  if (error) {
    switch (`${error}`) {
      case err("Verification required."):
        return <VerificationRequiredPage />;
      case err("This URL can be used only once"):
        return <UsedEmailVerificationPage />;
      case err("This account is already verified."):
        return <AlreadyVerifiedPage />;
      case err("Access expired."):
        return <VerificationExpiredPage />;
    }
    datadogLogs.logger.error("auth0 error", error);
    return <ErrorPage />;
  }

  if (loading || needsClient || switchingClient) {
    return <LinearProgress />;
  }

  return <AppRouter />;
};

export default RouteWrapper;
