// @ts-nocheck
import LinearProgress from "@mui/material/LinearProgress";
import { loginWithVerosintDeviceId } from "src/helpers/deviceid";

const SignUp = () => {
  const email = new URLSearchParams(window.location.search).get("login_hint");
  const authorizationParams = {
    screen_hint: "signup",
  };
  if (email) authorizationParams.login_hint = email;
  loginWithVerosintDeviceId({
    authorizationParams,
  });
  return <LinearProgress />;
};

export default SignUp;
