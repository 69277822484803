// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import superSteve from "./super-steve.png";
import darkBatch from "./dark-batch.png";

const duration = 4000;
let angle = -45;
let startTime;

let imgObj;
let imgRight;
let imgBottom;

const animateImage = (timestamp) => {
  if (!startTime) startTime = timestamp;
  const progress = timestamp - startTime;
  const progressPercentage = progress / duration;
  if (progressPercentage > 1) {
    imgObj.style.display = "none";
    return;
  }
  angle = -progressPercentage * 90;
  imgObj.style.transform = `rotate(${angle}deg)`;
  imgObj.style.right = `${imgRight}px`;
  imgObj.style.bottom = `${imgBottom}px`;
  imgRight += 8;
  imgBottom += 4;
  requestAnimationFrame(animateImage);
};

function animate(imgRef) {
  imgObj = imgRef;
  startTime = null;
  imgObj.style.display = "block";
  imgRight = 0;
  imgBottom = 0;
  requestAnimationFrame(animateImage);
}

const style = {
  position: "absolute",
  transform: "rotate(-45deg)",
  bottom: 0,
  right: 0,
  zIndex: 9999,
  display: "none",
};

const keysPressed = [];

const Easter = () => {
  const [show, setShow] = useState(false);
  const ss = useRef();
  const db = useRef();
  const s443id = "83,52,52,51,73,68";
  const b443id = "66,52,52,51,73,68";

  function startAnimation(ref) {
    setShow(true);
    if (ref.current) {
      animate(ref.current);
    } else {
      setTimeout(() => {
        animate(ref.current);
      }, 500);
    }
  }

  function detect443id(event) {
    keysPressed.push(event.keyCode);
    if (keysPressed.length > 6) {
      keysPressed.shift();
    }
    if (keysPressed.toString() === s443id) {
      startAnimation(ss);
    }
    if (keysPressed.toString() === b443id) {
      startAnimation(db);
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", detect443id);
    return () => {
      document.removeEventListener("keydown", detect443id);
    };
  }, []);

  return (
    <div>
      {show && (
        <div style={{ position: "relative" }}>
          <img
            ref={ss}
            alt="superSteve"
            id="superSteve"
            src={superSteve}
            style={style}
          />
          <img
            ref={db}
            alt="darkBatch"
            id="darkBatch"
            src={darkBatch}
            style={style}
          />
        </div>
      )}
    </div>
  );
};

export default Easter;
