import "./themeExtension.d";
import {
  createTheme,
  Palette,
  Theme,
  TypographyVariants,
} from "@mui/material/styles";

const { palette } = createTheme();

const lightTheme = {
  palette: {
    mode: "light",
    // CTA Blue
    primary: {
      main: "#0070D1",
      contrastText: "rgb(245,245,245)",
      light: "",
      dark: "",
    },
    // Dark Navy
    secondary: {
      main: "#142748",
      contrastText: "rgb(245,245,245)",
      light: "",
      dark: "",
    },
    // Medium Blue
    hColor: {
      main: "#35527E",
      contrastText: "rgb(245,245,245)",
      light: "",
      dark: "",
    },
    success: {
      main: "#4CAF50",
      contrastText: "rgb(245,245,245)",
      light: "",
      dark: "",
    },
    warning: {
      main: "#FFB547",
      contrastText: "rgb(245,245,245)",
      light: "",
      dark: "",
    },
    highlight: palette.augmentColor({
      color: {
        main: "#01CCFF",
        dark: "#01CCFF",
      },
    }),
    // Light Gray
    background: {
      default: "#F4F4F4",
      paper: "#FDFDFD",
    },
    // Off White
    cardBackground: {
      default: "#FDFDFD",
    },
    // Medium Gray
    border: {
      default: "#D6D6D6",
    },
    // CTA Orange
    accent: palette.augmentColor({
      color: {
        main: "#FE5000",
      },
    }),
    darkAccent: palette.augmentColor({
      color: {
        main: "#373B49",
      },
    }),
    verosintBlue: palette.augmentColor({
      color: {
        main: "#00CCFF",
      },
    }),
    verosintNavy: palette.augmentColor({
      color: {
        main: "#1B365F",
      },
    }),
    charcoal: palette.augmentColor({
      color: {
        main: "#293135",
      },
    }),
    darkGray: palette.augmentColor({
      color: {
        main: "#646A70",
      },
    }),
    alertRed: palette.augmentColor({
      color: {
        main: "#E31B0C",
      },
    }),
    slateBlue: palette.augmentColor({
      color: {
        main: "#345990",
      },
    }),
    systemTag: palette.augmentColor({
      color: {
        main: "rgb(244,67,54)",
      },
    }),
    userTag: palette.augmentColor({
      color: {
        main: "rgb(102,102,102)",
      },
    }),
    payment: palette.augmentColor({
      color: {
        main: "#D5EFE5",
        dark: "rgb(89, 191, 150)",
      },
    }),
    phone: palette.augmentColor({
      color: {
        main: "#EDC3F0",
        dark: "#D100B0",
      },
    }),
    email: palette.augmentColor({
      color: {
        main: "#BFDBF3",
        dark: "rgb(0, 112, 209)",
      },
    }),
    ip: palette.augmentColor({
      color: {
        main: "#FCDDC7",
        dark: "rgb(244, 120, 32)",
      },
    }),
    print: palette.augmentColor({
      color: {
        main: "#FFECBF",
        dark: "rgb(255, 180, 0)",
      },
    }),
    event: palette.augmentColor({
      color: {
        main: "#DEE0E2",
        dark: "rgb(244,67,54)",
      },
    }),
    account: palette.augmentColor({
      color: {
        main: "#CCD4DF",
        dark: "rgb(53, 82, 126)",
      },
    }),
    tag: palette.augmentColor({
      color: {
        main: "rgb(233, 233, 233)",
      },
    }),
    userAgent: palette.augmentColor({
      color: {
        main: "#BFF2FF",
      },
    }),
    subduedGray: palette.augmentColor({
      color: {
        main: "#7C848B",
      },
    }),
    mustard: palette.augmentColor({
      color: {
        main: "#F1C600",
      },
    }),
  } as Palette,
  typography: {
    subtext: {
      color: "#0E5A81",
    },
    fontFamily: "Karla, -apple-system",
    h1: {
      fontFamily: "Lato, -apple-system",
      color: "#35527E",
      fontSize: "36px",
      display: "block",
      fontWeight: 400,
    },
    h2: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 700,
      color: "#35527E",
      fontSize: "28px",
      display: "block",
    },
    h2Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#142748",
      fontSize: "28px",
      display: "block",
    },
    h3: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#35527E",
      fontSize: "20px",
      display: "block",
    },
    h3Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#142748",
      fontSize: "20px",
      display: "block",
    },
    h4: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#35527E",
      fontSize: "18px",
      display: "block",
    },
    h4Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 400,
      color: "#142748",
      fontSize: "18px",
      display: "block",
    },
    h5: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#35527E",
      fontSize: "16px",
      textTransform: "uppercase",
      display: "block",
    },
    h5Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#142748",
      fontSize: "16px",
      display: "block",
    },
    h6: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#35527E",
      fontSize: "14px",
      textTransform: "uppercase",
      display: "block",
    },
    h6Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#142748",
      fontSize: "14px",
      display: "block",
    },
    p: {
      fontFamily: "Karla, -apple-system",
      fontSize: "16px",
      color: "#142748",
      display: "block",
    },
    pBold: {
      fontFamily: "Karla, -apple-system",
      fontSize: "16px",
      color: "#142748",
      fontWeight: 700,
      display: "block",
    },
    pSub: {
      fontFamily: "Karla, -apple-system",
      fontSize: "16px",
      color: "#7C848B",
      display: "block",
    },
    p2: {
      fontFamily: "Karla, -apple-system",
      fontSize: "14px",
      color: "#142748",
      display: "block",
    },
    p2Sub: {
      fontFamily: "Karla, -apple-system",
      fontSize: "14px",
      color: "#7C848B",
      display: "block",
    },
    bigNumber: {
      fontFamily: "Lato, -apple-system",
      fontSize: "40px",
      fontWeight: "700",
      color: "rgb(35, 78, 162)",
      display: "block",
    },
    deltaDecrease: {
      fontFamily: "Lato, -apple-system",
      fontSize: "24px",
      fontWeight: "700",
      color: "rgb(244, 66, 54)",
      display: "block",
    },
    deltaIncrease: {
      fontFamily: "Lato, -apple-system",
      fontSize: "24px",
      fontWeight: "700",
      color: "rgb(37, 173, 21)",
      display: "block",
    },
    code: {
      fontFamily: "monospace",
      backgroundColor: "rgb(239, 240, 242)",
      color: "#35527E",
      padding: "1px 8px",
      fontSize: "14px",
    },
  } as TypographyVariants,
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.25)",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          fontSize: "11px",
          height: "26px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#FDFDFD",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "printid" },
          style: {
            textTransform: "none",
            display: "inline",
            margin: 0,
            padding: 0,
            fontFamily: "monospace",
            color: "#0070D1",
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#FDFDFD",
          border: "1px solid #D6D6D6",
          borderRadius: "4px",
          boxShadow: "none",
          paddingBottom: "0",
        },
      },
    },
  },
};

export default createTheme(lightTheme as Theme);
