import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useClientNavigate from "src/hooks/useClientNavigate";
import { getClientUuid } from "../helpers/security";
import { useAuth0 } from "@auth0/auth0-react";
import { loginWithVerosintDeviceId } from 'src/helpers/deviceid';

const oldPaths = [
  "/ai-insights",
  "/dashboard/overview",
  "/dashboard/account-takeover",
  "/dashboard/account-sharing",
  "/dashboard/multiple-accounts",
  "/dashboard/impossible-travel",
  "/dashboard/signals",
  "/contact-support",
  "/account/upgrade",
  "/account/plans",
  "/account/api-keys",
  "/account/account-settings",
  "/account/user-settings",
  "/signalprint",
  "/event-explorer",
];

function useRedirectOldPaths() {
  let location = useLocation();
  const navigate = useClientNavigate();
  const clientUUID = getClientUuid();
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    const returnTo = window.location.pathname + window.location.search;
    loginWithVerosintDeviceId({ appState: { returnTo } });
  }

  function isOldPath(path: string) {
    return oldPaths.includes(path);
  }

  useEffect(() => {
    if (!clientUUID) return;
    if (isOldPath(location.pathname)) {
      navigate(location.pathname + location.search);
    }
  }, [location, clientUUID]);

  return isOldPath(location.pathname);
}

export default useRedirectOldPaths;
