import useClientNavigate from "src/hooks/useClientNavigate";
import { useEffect } from "react";
import { LinearProgress } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { getClientUuid } from "../helpers/security";
import { loginWithVerosintDeviceId } from "src/helpers/deviceid";

const Redirect = () => {
  const navigate = useClientNavigate();
  const clientUUID = getClientUuid();
  const homepage = "/ai-insights";
  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    const returnTo = window.location.pathname + window.location.search;
    loginWithVerosintDeviceId({ appState: { returnTo } });
  }
  useEffect(() => {
    if (!isAuthenticated) return;
    if (!clientUUID) return;
    navigate(homepage);
  }, [isAuthenticated, clientUUID]);
  return <LinearProgress />;
};

export default Redirect;
