// @ts-nocheck
/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  csvData: [],
  csvFiles: [],
  duplicateRows: [],
  invalidRows: [],
  csvUploadError: false,
  openCSVErrorModal: false,
  csvDuplicateWarning: false,
  uploadCount: 0,
  isLoading: 0,
  bulkScores: [],
};

const CSVUploadSlice = createSlice({
  name: "CSVUploadSlice",
  initialState,
  reducers: {
    setCsvData: (state, action) => {
      // disable modal for now
      // if( state.invalidRows.length || state.duplicateRows.length ){
      //   state.openCSVErrorModal = true;
      // }
      if (state.invalidRows.length > 0) {
        state.csvUploadError = true;
      }
      if (state.duplicateRows.length > 0) {
        state.csvDuplicateWarning = true;
      }
      state.csvData = action.payload;
    },
    setCsvFiles: (state, action) => {
      state.csvFiles = action.payload;
    },
    setCsvUploadError: (state, action) => {
      state.csvUploadError = action.payload;
    },
    setOpenCSVErrorModal: (state, action) => {
      state.openCSVErrorModal = action.payload;
    },
    setCsvDuplicateWarning: (state, action) => {
      state.csvDuplicateWarning = action.payload;
    },
    setDuplicateRows: (state, action) => {
      state.duplicateRows = action.payload;
    },
    setInvalidRows: (state, action) => {
      state.invalidRows = action.payload;
    },
    setBulkScores: (state, action) => {
      state.bulkScores = [...state.bulkScores, ...action.payload];
    },
    setUploadCount: (state, action) => {
      state.uploadCount = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    removeFile: (state) => {
      state.csvFiles = [];
      state.csvData = [];
      state.invalidRows = [];
      state.duplicateRows = [];
      state.csvDuplicateWarning = false;
      state.csvUploadError = false;
      state.openCSVErrorModal = false;
    },
    reset: () => initialState,
  },
});

export const {
  setCsvData,
  setCsvFiles,
  setDuplicateRows,
  setInvalidRows,
  setCsvUploadError,
  setOpenCSVErrorModal,
  setCsvDuplicateWarning,
  setBulkScores,
  setUploadCount,
  setIsLoading,
  reset,
  removeFile,
} = CSVUploadSlice.actions;

export default CSVUploadSlice.reducer;
