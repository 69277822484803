import { useEffect } from "react";
import { getClientUuid, loadClient } from "../helpers/security";
import { useAuth0 } from "@auth0/auth0-react";
import { validate as uuidValidate } from "uuid";

type Client = { clientUUID: string };
type Profile = {
  clients: Client[];
};

function useClientSwitcher(profile: Profile) {
  const clientUUID = getClientUuid();
  const pathArray = window.location.pathname.split("/");
  const pathClientUUID = pathArray?.[1];
  const isUUID = uuidValidate(pathClientUUID);
  const { isAuthenticated } = useAuth0();

  const isValidClient =
    profile?.clients?.filter((client: { clientUUID: string }) => {
      return client.clientUUID === pathClientUUID;
    })?.length > 0;

  const isDifferentUUID = pathClientUUID !== clientUUID;
  const newUUIDisValid = isUUID && isValidClient;
  const shouldSwitchClient = isDifferentUUID && newUUIDisValid;

  useEffect(() => {
    if (!isAuthenticated || !pathClientUUID || !clientUUID || !isUUID) return;
    if (shouldSwitchClient) {
      loadClient(pathClientUUID);
    }
  }, [
    isAuthenticated,
    pathClientUUID,
    clientUUID,
    isUUID,
    profile,
    shouldSwitchClient,
  ]);

  return shouldSwitchClient;
}

export default useClientSwitcher;
