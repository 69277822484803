// @ts-nocheck
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import useNotifier from "src/helpers/useNotifier";
import useScrollToTop from "src/hooks/useScrollToTop";
import WelcomeChecklist from "../Signup/WelcomeChecklist/WelcomeChecklist";
import NavBar from "./NavBar";
import NavDrawer from "./NavDrawer/NavDrawer";
import UserSelector from "../../redux/User/selector";
import { useSelector } from "src/redux/index";
import {
  useGetMetricsQuery,
  usePostEventSearchMutation,
} from "../../services/443id";
import { Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { isClientReadOnly } from "src/helpers/security";
import { useDispatch } from "react-redux";
import { setMetricsResponse } from "src/redux/Metrics/slice";
import { setHasNoEvents } from "src/redux/User/slice";
import dayjs from "dayjs";
import { datadogLogs } from "@datadog/browser-logs";
import { EventRequest } from "src/types/EventRequest";
import { EventResponse } from "src/types/EventResponse";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const drawerWidth = 246;
const indexPadding = "20px !important";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    marginLeft: `
    -${drawerWidth}px`,
    ...(open && {
      [theme.breakpoints.up("md")]: {
        marginLeft: 0,
      },
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const Layout = () => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [openDrawer, setDrawerOpen] = useState(!isSM);
  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const { data: metricsData, isLoading: isMetricsLoading } = useGetMetricsQuery(
    undefined,
    {
      skip: !isAuthenticated,
    }
  );
  const [fetchEventData] = usePostEventSearchMutation();
  const { showLimitExceededMsg } = useSelector(UserSelector);
  const isTrial = metricsData?.planName?.includes("trial");
  const readOnly = isClientReadOnly();

  useNotifier();
  useScrollToTop();

  useEffect(() => {
    setDrawerOpen(!isSM);
  }, [isSM]);

  const runFetchEventData = () => {
    const q: EventRequest = {
      limit: 0,
      timeRange: [
        dayjs().utc().subtract(180, "day").toISOString(),
        dayjs().toISOString(),
      ],
    };
    fetchEventData(q)
      .unwrap()
      .then((d: EventResponse) => {
        if (d?.total === 0) {
          dispatch(setHasNoEvents(true));
        }
      })
      .catch((error: any) => {
        datadogLogs.logger.error(
          "failure to fetch events count for welcome modal: ",
          error
        );
      });
  };

  useEffect(() => {
    if (metricsData) {
      dispatch(setMetricsResponse(metricsData));
      runFetchEventData();
    }
  }, [metricsData, isMetricsLoading]);

  const handleDrawerOpen = () => {
    setDrawerOpen(!openDrawer);
  };

  return (
    <>
      <WelcomeChecklist />
      <Box sx={{ display: "flex" }} className={readOnly ? "read-only" : ""}>
        <CssBaseline />
        <NavBar
          openDrawer={openDrawer}
          handleDrawerOpen={handleDrawerOpen}
          padding={indexPadding}
          showUpgradeButton={isTrial || metricsData?.planName === "free"}
          showLimitExceededMessage={showLimitExceededMsg}
        />
        <NavDrawer
          drawerWidth={drawerWidth}
          handleDrawerOpen={handleDrawerOpen}
          openDrawer={openDrawer}
          isSM={isSM}
        />
        <Main
          className="main"
          sx={{
            flexGrow: 1,
            minWidth: 0,
            mt: 2,
          }}
          open={openDrawer}
          onClick={() => isSM && setDrawerOpen(false)}
        >
          <div style={{ minHeight: "48px" }} />
          <Outlet />
        </Main>
      </Box>
    </>
  );
};

export default Layout;
