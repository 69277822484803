import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import { IconButton, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateCurrentOption, updateNavPath } from "../../../redux/User/slice";
import styles from "./NavDrawer.module.scss";
import useClientNavigate from "src/hooks/useClientNavigate";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { isClientFree } from "src/helpers/security";
import { useTheme } from "@mui/material/styles";

const UpgradeButton = () => {
  const { t } = useTranslation();
  const navigate = useClientNavigate();
  const theme = useTheme();

  return (
    <IconButton
      id="upgrade-button"
      data-testid="upgrade-button"
      size="small"
      sx={{
        backgroundColor: theme.palette.highlight.main,
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: theme.palette.highlight.main,
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
        navigate("/contact-sales", {
          prepopulatedMessage: t("contact.sales.prepopulatedMessage"),
        });
      }}
    >
      <Typography
        color={theme.palette.verosintNavy.main}
        sx={{
          fontSize: "12px",
          whiteSpace: "nowrap",
          fontWeight: "bold",
        }}
      >
        {t("navBar.upgradeBtn")}
      </Typography>
      <ArrowOutwardIcon
        color={theme.palette.verosintNavy.main}
        sx={{
          width: "16px",
          height: "16px",
          verticalAlign: "middle",
          marginLeft: "4px",
        }}
      />
    </IconButton>
  );
};

type Props = {
  drawerWidth: number;
  openDrawer: boolean;
  handleDrawerOpen: Function;
  isSM: boolean;
};

const NavDrawer = (props: Props) => {
  const { drawerWidth, openDrawer, handleDrawerOpen, isSM } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useClientNavigate();
  const { pathname } = useLocation();
  const isFree = isClientFree();

  const setCurrentOption = (currentOption: string, title?: string) => {
    if (isSM) {
      handleDrawerOpen();
    }
    dispatch(updateNavPath(title));
    dispatch(updateCurrentOption(currentOption));
    navigate(`${currentOption}`);
  };

  return (
    <Drawer
      className="nav-drawer"
      variant="persistent"
      anchor="left"
      sx={{
        width: drawerWidth,
        backgroundColor: "#f4f4f4",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
        visibility: openDrawer ? "inherit" : "hidden",
        transform: openDrawer ? "inherit" : "translateX(-246px)",
      }}
      open={openDrawer}
    >
      <Toolbar />
      <List
        className={styles.itemList}
        data-testid="navdrawer_list"
        sx={{ mt: 4 }}
      >
        <ListItem sx={{ pb: 2 }}>
          <Typography
            fontSize="15px"
            fontWeight="700"
            sx={{ textTransform: "uppercase" }}
          >
            {t("navDrawer.intelligence")}
          </Typography>
        </ListItem>

        <ListItemButton
          key="ai-insights"
          data-testid="goto-dashboard-ai-insights-link"
          selected={pathname.includes("/ai-insights")}
          onClick={() => setCurrentOption("/ai-insights")}
        >
          <ListItemIcon>
            <AutoAwesomeOutlinedIcon className="list_icon_color" />
          </ListItemIcon>
          <ListItemText primary={t("navDrawer.aiInsights")} />
        </ListItemButton>

        <ListItemButton
          key="overview"
          data-testid="goto-dashboard-overview-link"
          selected={pathname.includes("/dashboard/overview")}
          onClick={() => setCurrentOption("/dashboard/overview")}
        >
          <ListItemIcon>
            <DashboardOutlinedIcon className="list_icon_color" />
          </ListItemIcon>
          <ListItemText primary={t("navDrawer.overview")} />
        </ListItemButton>

        <ListItemButton
          key="accounts"
          data-testid="goto-accounts-link"
          selected={pathname.includes("/accounts")}
          onClick={() => setCurrentOption("/accounts")}
        >
          <ListItemIcon>
            <PeopleOutlineOutlinedIcon className="list_icon_color" />
          </ListItemIcon>
          <ListItemText primary={t("accountDetails.accounts")} />
        </ListItemButton>

        {/* Event Explorer */}
        <ListItemButton
          key="event-explorer"
          data-testid="goto-event-explorer-link"
          selected={pathname.includes("/event-explorer")}
          onClick={() =>
            setCurrentOption("/event-explorer", t("eventExplorer.pageTitle"))
          }
        >
          <ListItemIcon>
            <ExploreOutlinedIcon className="list_icon_color" />
          </ListItemIcon>
          <ListItemText primary={t("eventExplorer.pageTitle")} />
        </ListItemButton>

        {/* Signal Print */}
        <ListItemButton
          key="signalPrint"
          data-testid="goto-signalPrint-link"
          selected={pathname.includes("/signalprint")}
          onClick={() => setCurrentOption("/signalprint")}
        >
          <ListItemIcon>
            <FingerprintOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t("signalPrint.navTitle")} <sup>&trade;</sup>
          </ListItemText>
        </ListItemButton>

        <ListItem sx={{ pt: 3, pb: 2 }}>
          <Typography
            fontSize="15px"
            fontWeight="700"
            sx={{ textTransform: "uppercase" }}
          >
            {t("navDrawer.prevention")}
          </Typography>
        </ListItem>

        {/* Lists */}
        <ListItem key="lists" disablePadding>
          <ListItemButton
            data-testid="goto-lists-link"
            selected={pathname.includes("/lists")}
            onClick={() => setCurrentOption("/lists", t("lists.pageTitle"))}
            disabled={isFree}
          >
            <ListItemIcon>
              <FormatListBulletedIcon className="list_icon_color" />
            </ListItemIcon>
            <ListItemText primary={t("lists.pageTitle")} />
            {isFree && <UpgradeButton />}
          </ListItemButton>
        </ListItem>

        {/* Workflows */}
        <ListItem key="workflows" disablePadding>
          <ListItemButton
            data-testid="goto-workflows-link"
            selected={pathname.includes("/workflows")}
            onClick={() =>
              setCurrentOption("/workflows", t("workflows.listTitle"))
            }
            disabled={isFree}
          >
            <ListItemIcon>
              <RuleOutlinedIcon className="list_icon_color" />
            </ListItemIcon>
            <ListItemText primary={t("workflows.listTitle")} />
            {isFree && <UpgradeButton />}
          </ListItemButton>
        </ListItem>
      </List>
      {/* Contact Support */}
      <List sx={{ bottom: 0, marginTop: "auto" }}>
        <Divider className="drawer_divider" />
        <ListItem sx={{ pb: 2, pt: 2 }}>
          <Typography
            fontSize="15px"
            fontWeight="700"
            sx={{ textTransform: "uppercase" }}
          >
            {t("navDrawer.tools")}
          </Typography>
        </ListItem>

        {/* Documentation */}
        <ListItemButton
          key="goto-documentation"
          data-testid="goto-documentation-link"
          component={Link}
          href="https://docs.verosint.com/"
          target="_blank"
          onClick={() => (isSM ? handleDrawerOpen() : null)}
        >
          <ListItemIcon sx={{ minWidth: "40px !important" }}>
            <DescriptionOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={t("documentation.listTitle")} />
        </ListItemButton>
        <ListItemButton
          key="contact-support"
          data-testid="goto-contact-support-link"
          onClick={() => setCurrentOption("/contact-support")}
        >
          <ListItemIcon sx={{ minWidth: "40px !important" }}>
            <ContactSupportOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={t("contact.support.title")} />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default NavDrawer;
