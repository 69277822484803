// @ts-nocheck
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  const { t } = useTranslation();
  const brandName = t("global.brandName");

  useEffect(() => {
    document.title = title ? `${title} | ${brandName}` : brandName;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );
}

export default useDocumentTitle;
