import { LinearProgress } from "@mui/material";
import { loginWithVerosintDeviceId } from "src/helpers/deviceid";

const Invitation = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("email");
  const invitationId = searchParams.get("invitationId");
  const clientUUID = searchParams.get("clientUUID");

  loginWithVerosintDeviceId({
    authorizationParams: {
      login_hint: email ?? "",
      screen_hint: "signup",
      email,
      invitationId,
      clientUUID,
    },
  });
  return <LinearProgress />;
};

export default Invitation;
