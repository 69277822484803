import { Box } from "@mui/material";
import WaterMark from "../../components/Layout/WaterMark";
import { Outlet } from "react-router-dom";

const SetupLayout = () => {
  return (
    <Box>
      <WaterMark>
        <Outlet />
      </WaterMark>
    </Box>
  );
};

export default SetupLayout;
