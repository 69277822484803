/* eslint-disable no-unused-vars */
import { Filter } from "./Filter";

export interface EventGroupByRequest {
  /**
   * The aggregations to apply to the matching events. When events are grouped, this applies
   * to the events in each group
   */
  aggregations?: Aggregation[];
  /**
   * Controls how to return the count of matching events over time, When events are grouped,
   * this has no effect
   */
  counts?: Counts;
  /**
   * The filters to to apply to the events
   */
  filters?: Filter[];
  /**
   * The field to group the matching events. By default, only the count of events in each
   * group will be returned
   */
  groupBy?: string;
  /**
   * Controls how to return events with the attribute value. THese events are not influenced
   * by the filter and time range from the request
   */
  events?: Events;
  /**
   * The maximum number of events to return. When events are grouped, the maximum number of
   * groups to return
   */
  limit?: number;
  /**
   * The time range of events to return
   */
  timeRange: string[];
  /**
   * The fields for which to return the most frequently occurring values
   */
  topValues?: TopValueRequest[];
}

export interface Events {
  /**
   * The maximum number of events to return. When events are grouped, the maximum number of
   * groups to return
   */
  limit?: number;
  /**
   * The fields from the matching events to return. Wildcards are allowed (ie. * will return
   * all fields, ip.* will return only IP signal fields). When events are grouped, this has no
   * effect
   */
  fields?: string[];
  /**
   * The order to return the matching events. When events are grouped, this has no effect
   */
  orders?: OrderElement[];
}

export interface Aggregation {
  aggregator: AggregatorEnum;
  field: string;
  name: string;
  /**
   * Returns the average number of distinct values within the specified time window
   */
  period?: Period;
  /**
   * The number of most frequently occurring values to return
   */
  limit?: number;
  [property: string]: any;
}

export enum AggregatorEnum {
  Avg = "avg",
  Count = "count",
  DistinctCount = "distinctCount",
  Max = "max",
  Min = "min",
  MostRecent = "mostRecent",
  TopN = "topN",
}

/**
 * Returns the average number of distinct values within the specified time window
 */
export enum Period {
  Daily = "daily",
  Monthly = "monthly",
  Weekly = "weekly",
}

/**
 * Controls how to return the count of matching events over time, When events are grouped,
 * this has no effect
 */
export interface Counts {
  /**
   * The aggregations to apply to the events in each time bucket
   */
  aggregations?: Aggregation[];
  /**
   * The desired target number of time buckets. The buckets returned may be less than this
   * number
   */
  buckets?: number;
  /**
   * The granularity of the time buckets to return counts
   */
  granularity?: string;
  /**
   * The field to group the counts by
   */
  groupBy?: string;
  /**
   * The timezone to used to determine the boundaries of the time buckets
   */
  timeZone?: string;
}

/**
 * The granularity of the time buckets to return counts
 */
export enum Granularity {
  Day = "day",
  Hour = "hour",
  Minute = "minute",
  Month = "month",
  Week = "week",
}

export interface OrderElement {
  /**
   * The field to order by
   */
  field: string;
  /**
   * Whehther to sort the field in ascending or descending order
   */
  order: OrderEnum;
}

/**
 * Whehther to sort the field in ascending or descending order
 */
export enum OrderEnum {
  Asc = "asc",
  Desc = "desc",
}

export interface TopValueRequest {
  /**
   * The field to return the most frequently occurring values
   */
  field: string;
  /**
   * The number of most frequently occurring values to return
   */
  limit?: number;
}
