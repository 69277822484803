// @ts-nocheck
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import ErrorPage from "src/pages/error/ErrorPage";
import { ThemeProvider } from "@mui/material/styles";

import lightTheme from "src/styles/mainTheme";

const BoundaryFallback = () => (
  <ThemeProvider theme={lightTheme}>
    <CssBaseline />
    <ErrorPage />
  </ThemeProvider>
);

export default BoundaryFallback;
