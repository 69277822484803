import { Box } from "@mui/material";
import { CSSProperties, ReactNode } from "react";
import { useTheme } from "@mui/material/styles";

type Props = {
  children: ReactNode;
  style?: CSSProperties;
  childStyle?: CSSProperties;
};

const HeaderSection = ({ children, style = {}, childStyle = {} }: Props) => {
  const theme = useTheme();
  return (
    <Box
      id="header-section"
      sx={{
        width: "100%",
        background: theme.palette.cardBackground.default,
        borderBottom: `1px solid ${theme.palette.border.default}`,
        p: "36px 12px 36px 0",
        ...style,
      }}
    >
      <Box sx={{ maxWidth: 1200, pr: 2, pl: 2, ...childStyle }}>{children}</Box>
    </Box>
  );
};

export default HeaderSection;
