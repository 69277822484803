// @ts-nocheck
/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import exampleScores from "./example-scores.json";

const get = require("lodash/get");

const initialState = {
  isLoading: false,
  showUserData: false,
  drillDownModalOpen: false,
  drillDownModalSignalsOpen: false,
  drillDownModalScoresOpen: false,
  drillDownModalFilter: "",
  drillDownModalTitle: "",
  showUpload: false,
  showUploadBtn: true,
  totalSignals: 0,
  bulkScores: [],
  userScores: [],
  drillDown: [],
  filename: "",
  exampleScores,
  userCount: 0,
  progress: {
    upload: false,
    playground: false,
    policy: false,
    apiKey: false,
  },
};

const GettingStartedSlice = createSlice({
  name: "gettingStartedSlice",
  initialState,
  reducers: {
    setProgress: (state, action) => {
      state.progress = { ...state.progress, ...action.payload };
    },
    setUserScores: (state, action) => {
      state.userScores = action.payload;
    },
    setDrillDownModalScoresOpen: (state, action) => {
      state.drillDownModalScoresOpen = action.payload;
    },
    setDrillDownModalSignalsOpen: (state, action) => {
      state.drillDownModalSignalsOpen = action.payload;
    },
    setDrillDownModalOpen: (state, action) => {
      state.drillDownModalOpen = action.payload;
    },
    setDrillDownModalFilter: (state, action) => {
      state.drillDownModalFilter = action.payload;
    },
    setDrillDownModalTitle: (state, action) => {
      state.drillDownModalTitle = action.payload;
    },
    setUploadCount: (state, action) => {
      state.uploadCount = action.payload;
    },
    setFilename: (state, action) => {
      state.filename = action.payload;
    },
    setShowUserData: (state, action) => {
      state.showUserData = action.payload;
    },
    setDrillDown: (state, action) => {
      state.drillDown = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setShowUpload: (state, action) => {
      state.showUpload = action.payload;
    },
    setShowUploadBtn: (state, action) => {
      state.showUploadBtn = action.payload;
    },
    resetGraphData: (state) => {
      state.bulkScores = [];
      state.totalSignals = 0;
      state.userCount = 0;
    },
    setBulkScores: (state, action) => {
      const newScores = action.payload.map((sd) => {
        const categories = get(sd, "value.data.categories", []) || [];
        const categoryData = categories?.reduce((acc, category) => {
          category?.signals?.forEach((signal) => {
            acc.push({
              category: category.name,
              signal: signal.name,
            });
          });
          return acc;
        }, []);
        const derived = {
          categoryData,
          signalCount: categoryData.length,
        };
        const value = {
          ...sd.value,
          derived,
        };
        return {
          value,
          payload: sd.payload,
        };
      });
      state.bulkScores = state.bulkScores.concat(newScores);
      state.totalSignals = state.bulkScores.reduce((p, v) => {
        const count = get(v, "value.derived.signalCount", 0);
        return p + count;
      }, 0);
      state.userCount = state.bulkScores.filter((s) => {
        return get(s, "value.data.requestId");
      }).length;
    },
    reset: () => initialState,
  },
});

export const {
  setProgress,
  setUploadCount,
  setShowUserData,
  setUserScores,
  setExampleScores,
  setDrillDown,
  setIsLoading,
  setShowUpload,
  setShowUploadBtn,
  setFilename,
  resetGraphData,
  setBulkScores,
  setDrillDownModalSignalsOpen,
  setDrillDownModalScoresOpen,
  setDrillDownModalOpen,
  setDrillDownModalFilter,
  setDrillDownModalTitle,
  reset,
} = GettingStartedSlice.actions;

export default GettingStartedSlice.reducer;
