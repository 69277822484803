const EmailErrorIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="120"
    width="120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path
        fill="#CCCCCC"
        d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h8v-2H4V8l8 5 8-5v5h2V6c0-1.1-.9-2-2-2zm-8 7L4 6h16l-8 5z"
      />
      <g transform="translate(13 13) scale(0.5)">
        <path
          fill="#fc0404"
          d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8z"
        />
        <path fill="#fc0404" d="M11 7h2v7h-2z" />
        <circle fill="#fc0404" cx="12" cy="16" r="1"></circle>
      </g>
    </svg>
  </svg>
);

export default EmailErrorIcon;
