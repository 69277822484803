// @ts-nocheck
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from "react-redux";
import storage from "redux-persist/lib/storage";
import { fftIdApi } from "../services/443id";
import notistackReducer from "./Notistack/slice";
import userReducer from "./User/slice";
import dashboardsReducer from "./Dashboards/slice";
import gettingStartedReducer from "./GettingStarted/slice";
import csvUploadReducer from "./CSVUpload/slice";
import accountReducer from "./Accounts/slice";
import metricsReducer from "./Metrics/slice";
import workflowReducer from "./Workflow/slice";

const rootReducer = combineReducers({
  accounts: accountReducer,
  gettingStarted: gettingStartedReducer,
  dashboards: dashboardsReducer,
  csvUpload: csvUploadReducer,
  user: userReducer,
  metrics: metricsReducer,
  workflow: workflowReducer,
  [fftIdApi.reducerPath]: fftIdApi.reducer,
  notistack: notistackReducer,
});

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export type RootState = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: "root",
  version: process.env.REACT_APP_BUILD_SHA,
  storage,
  whitelist: ["gettingStarted"],
  migrate: async (state) => {
    // eslint-disable-next-line no-underscore-dangle
    const isNewVersion =
      state?._persist?.version !== process.env.REACT_APP_BUILD_SHA;
    const newState = isNewVersion ? {} : state;
    return await Promise.resolve(newState);
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "notistack/enqueueSnackbar",
          "notistack/closeSnackbar",
        ],
        ignoredPaths: ["notistack"],
      },
    }).concat(fftIdApi.middleware),
});

export const persistor = persistStore(store);
export default store;

setupListeners(store.dispatch);
