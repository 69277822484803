// @ts-nocheck
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Modal from "@mui/material/Modal";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Typography from "@mui/material/Typography";
import React from "react";
import {
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import * as Papa from "papaparse";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { downloadFile } from "src/helpers/utils";
import CSVUploadSelector from "src/redux/CSVUpload/selector";
import { useSelector } from "src/redux/index";
import { setOpenCSVErrorModal, removeFile } from "src/redux/CSVUpload/slice";

const CSVErrorModal = () => {
  const { t } = useTranslation();
  const { openCSVErrorModal, invalidRows, duplicateRows } =
    useSelector(CSVUploadSelector);
  const dispatch = useDispatch();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45rem",
    bgcolor: "background.paper",
    border: "unset",
    boxShadow: 24,
    p: 4,
  };

  const errorColor = "#F44336";

  function downloadCSV() {
    const columns = [
      "rowNumber",
      "email",
      "invalidEmail",
      "ipAddress",
      "invalidIPAddress",
      "phone",
      "invalidPhone",
    ];
    const csv = Papa.unparse(invalidRows, { columns });
    downloadFile(
      csv,
      t("evaluator.csvErrorFilename"),
      "text/csv;charset=utf-8;"
    );
  }

  function onClose(e, reason) {
    const badReasons = ["escapeKeyDown", "backdropClick"];
    if (badReasons.includes(reason)) return;
    dispatch(setOpenCSVErrorModal(false));
  }

  return (
    <Modal
      data-testid="modal-close-id"
      open={openCSVErrorModal}
      onClose={(e, reason) => {
        onClose(e, reason);
      }}
      aria-labelledby="welcome-modal-title"
      aria-describedby="welcome-modal-description"
    >
      <Box sx={modalStyle}>
        <div>
          <div style={{ display: "inline-block" }}>
            <ErrorOutlineOutlinedIcon sx={{ color: errorColor }} />
          </div>
          <div style={{ display: "inline-block" }}>
            <Typography
              variant="h6"
              sx={{ color: errorColor, marginBottom: "24px" }}
            >
              {t("evaluator.modalTitle")}
            </Typography>
          </div>
          <div style={{ display: "inline-block" }}>
            <Tooltip
              title={t("evaluator.downloadErrorBtn")}
              placement="left"
              arrow
            >
              <Button
                data-testid="errorreport-download-button"
                sx={{ marginLeft: "435px" }}
                onClick={() => {
                  downloadCSV();
                }}
              >
                <FileDownloadOutlinedIcon color="primary" />
              </Button>
            </Tooltip>
          </div>
        </div>
        <FormGroup>
          {duplicateRows?.length > 0 && (
            <Typography paddingBottom="15px">
              <Trans
                t={t}
                i18nKey="evaluator.modalWarningDetected"
                count={duplicateRows.length}
              >
                Duplicates: There are <strong>5</strong> rows of duplicate
                identifiers. Duplicate rows will be skipped when processing
                results.
              </Trans>
            </Typography>
          )}
        </FormGroup>
        {invalidRows.length > 0 && (
          <Box>
            <Typography>
              <Trans
                t={t}
                i18nKey="evaluator.modalErrorDetected"
                count={invalidRows?.length || 0}
              >
                Invalid Formats: There are <strong>5</strong> rows of
                identifiers with invalid formats. To skip only invalid formats
                in a row, click
                <span style={{ fontWeight: "bold", color: "#4FAFDE" }}>
                  Skip Identifiers
                </span>
                . To upload a new CSV file, click{" "}
                <span style={{ fontWeight: "bold", color: "#4FAFDE" }}>
                  Remove File
                </span>
                .
              </Trans>
            </Typography>
            <Paper sx={{ overflow: "hidden", margin: "24px 0" }}>
              <TableContainer
                sx={{ maxHeight: "396px" }}
                className="always-show-scroll"
              >
                <Table stickyHeader aria-label="error table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ background: "white" }} align="center" />
                      <TableCell sx={{ background: "white" }} align="center">
                        <Typography fontWeight="bold">
                          {t("global.email")}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ background: "white" }} align="center">
                        <Typography fontWeight="bold">
                          {t("global.ipAddress")}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ background: "white" }} align="center">
                        <Typography fontWeight="bold">
                          {t("global.phone")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invalidRows?.map((row) => (
                      <TableRow
                        key={row.rowNumber}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {t("evaluator.rowNumber", { count: row.rowNumber })}
                        </TableCell>
                        <TableCell align="center">
                          {row.invalidEmail && (
                            <Tooltip
                              title={JSON.stringify(row.email)}
                              placement="top"
                              arrow
                            >
                              <Typography
                                fontWeight="bold"
                                sx={{ color: errorColor }}
                              >
                                X
                              </Typography>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.invalidIP && (
                            <Tooltip
                              title={JSON.stringify(row.ipAddress)}
                              placement="top"
                              arrow
                            >
                              <Typography
                                fontWeight="bold"
                                sx={{ color: errorColor }}
                              >
                                X
                              </Typography>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.invalidPhone && (
                            <Tooltip
                              title={JSON.stringify(row.phone)}
                              placement="top"
                              arrow
                            >
                              <Typography
                                fontWeight="bold"
                                sx={{ color: errorColor }}
                              >
                                X
                              </Typography>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        )}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={3}
        >
          <Grid item>
            <Button
              data-testid="skip-rows-button"
              onClick={() => dispatch(setOpenCSVErrorModal(false))}
              variant="text"
            >
              {t("evaluator.skipIdentifiersBtn")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-testid="remove-file-button"
              onClick={() => dispatch(removeFile())}
              variant="text"
            >
              {t("evaluator.removeFileBtn")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CSVErrorModal;
