import { getClientUuid } from "../helpers/security";
import { useNavigate } from "react-router-dom";

function useClientNavigate() {
  const clientUUID = getClientUuid();
  const navigate = useNavigate();

  // you can optionally pass state that can be accessed using
  // react-router-dom's useLocation hook
  return (path: string, state?: object, newWindow: boolean = false) => {
    newWindow
      ? window.open(`/${clientUUID}${path}`, "_blank")
      : navigate(`/${clientUUID}${path}`, {
          state,
        });
  };
}

export default useClientNavigate;
