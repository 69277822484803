import { Link, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useClientNavigate from "src/hooks/useClientNavigate";

const OktaSteps = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useClientNavigate();
  return (
    <>
      <ol
        style={{
          margin: 0,
          padding: "18px",
        }}
        data-testid="oktaWIC-list"
      >
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="p">
            <Trans t={t} i18nKey="welcomeChecklist.oktaStep1">
              text
              <Link
                sx={{ textDecoration: "none", fontWeight: "600" }}
                data-testid="oktaWIC-link"
                href="https://login.okta.com/"
                target="_blank"
              >
                link
              </Link>{" "}
              text
            </Trans>
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="p">
            <Trans t={t} i18nKey="welcomeChecklist.oktaStep2"></Trans>
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="p">
            <Trans
              t={t}
              i18nKey="welcomeChecklist.oktaStep3"
              components={{
                1: (
                  <span
                    onClick={() => {
                      navigator.clipboard
                        .writeText(
                          "https://api.verosint.com/v1/signalprint/logs"
                        )
                        .then(() => {
                          enqueueSnackbar(t("global.copied"), {
                            variant: "success",
                            autoHideDuration: 4000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                          });
                        })
                        .catch(() => {
                          enqueueSnackbar(t("global.copyFailed"), {
                            variant: "error",
                            autoHideDuration: 4000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                          });
                        });
                    }}
                    style={{ cursor: "pointer", fontWeight: "600" }}
                  />
                ),
              }}
            ></Trans>
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.oktaStep4" />
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="p">
            <Trans t={t} i18nKey="welcomeChecklist.oktaStep5">
              text
              <Link
                onClick={() => navigate("/account/api-keys", {})}
                sx={{
                  textDecoration: "none",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              ></Link>
            </Trans>
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.oktaStep6" />
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.oktaStep7" />
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="body1" component="div">
            <Trans t={t} i18nKey="welcomeChecklist.oktaStep8" />
          </Typography>
        </li>
        <li style={{ paddingBottom: "12px" }}>
          <Typography variant="p">
            <Trans t={t} i18nKey="welcomeChecklist.oktaStep9">
              text
              <Link
                sx={{ textDecoration: "none", fontWeight: "600" }}
                data-testid="oktaWIC-link"
                href="https://docs.verosint.com/docs/okta-wic-log-streaming"
                target="_blank"
              >
                link
              </Link>{" "}
              text
            </Trans>
          </Typography>
        </li>
      </ol>
    </>
  );
};

export default OktaSteps;
