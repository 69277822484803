import { createSlice } from "@reduxjs/toolkit";
import { Metrics } from "src/types/Metrics";

type State = {
  metricsData: Metrics | undefined;
};

const initialState: State = {
  metricsData: undefined,
};

const MetricsSlice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    setMetricsResponse: (state, action) => {
      state.metricsData = action.payload;
    },
  },
});

export const { setMetricsResponse } = MetricsSlice.actions;

export default MetricsSlice.reducer;
